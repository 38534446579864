import { LayoutPreloaderWide } from "../../components/layout-preloader-wide";
import { AuthConsumer } from "../../providers/auth";

export const Callback = () => (
	<AuthConsumer>
		{({ signinRedirectCallback }: any) => {
			signinRedirectCallback();
			return <LayoutPreloaderWide />;
		}}
	</AuthConsumer>
);
