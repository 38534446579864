import { RefObject, ReactElement, Ref } from "react";

interface ModalProps {
	show: boolean;
	hideModal?: any;
	overflow?: boolean;
	className?: string;
	wrapperClassName?: string;
	wrapperStyle?: any;
	children?: ReactElement | any;
	style?: { [key: string]: any };
	modalRef?: RefObject<any>;
}

export const ModalComponent: React.FC<ModalProps> = ({
	show,
	overflow,
	hideModal,
	className,
	children,
	wrapperClassName,
	wrapperStyle,
	style,
	modalRef,
}: ModalProps, ref?: Ref<HTMLElement>) => {
	if (show) {
		document.body.style.overflowY = "hidden";
	} else {
		document.body.style.overflowY = "scroll";
	}

	return (
		<>
			<div
				className={
					(wrapperClassName ?? " ") +
					" fixed z-90 inset-0 overflow-y-auto " +
					(show ? "" : "hidden")
				}
				style={wrapperStyle ?? { zIndex: 99999 }}
				aria-labelledby='modal-title'
				role='dialog'
				aria-modal='true'
			>
				<div className='flex relative items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
					<div
						className={
							"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " +
							(show
								? "ease-out duration-300 opacity-100"
								: "ease-in duration-200 opacity-0 hidden")
						}
						aria-hidden='true'
						onClick={hideModal}
					></div>

					<span
						className='hidden sm:inline-block sm:align-middle sm:h-screen'
						aria-hidden='true'
					>
						&#8203;
					</span>
					<div
						style={style ?? {
							left: "50%",
							transform: "translateX(-50%)",
						}}
						ref={modalRef}
						className={
							"inline-block bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-top " +
							(show
								? "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100"
								: "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95") +
							` ${overflow ? "overflow-hidden" : ""} ${
								className ??
								"max-w-md w-full top-40 sm:absolute md:absolute lg:absolute align-top"
							}`
						}
						role='dialog'
						aria-modal='true'
						aria-labelledby='modal-headline'
					>
						{children}
					</div>
				</div>
			</div>
		</>
	);
};
