import { useEffect, useState } from "react";
import { openBankingService } from "../../../services/open-banking";
import { toast } from "../../../utils/toast";
import { EcommerceConnectionsComponent } from "../../connections/ecommerce";
import { EcommercePaymentsComponent } from "../../connections/payments";

interface Props {
	callback: any;
}

export function OnboardingConnectionsComponent(props: Props & any) {
	const { mixpanel, triggerRefreshData } = props;
	const [ecommerceModal, toggleEcommerceModal] = useState(false);
	const [paymentsModal, togglePaymentsModal] = useState(false);
	const [connectBankAccount, setConnectBankAccount] = useState(0);

	useEffect(() => {
		if (!props.organisation?.id) {
			triggerRefreshData();
		}

		async function handleOpenBankingAuthorization(id: string, code: string) {
			try {
				const response = await openBankingService.exchangeToken(id, code);
				if (response.status === 204) {
					setConnectBankAccount(1);
					await mixpanel.track("Connect Banking", {
						Platform: "TrueLayer",
						"Platform Type": "Open Banking",
						"Connection Status": "Connected",
					});
					toast({
						text: "Bank account connection successful.",
						type: "info",
					});
				}
			} catch (error) {
				toast({
					text: "Bank account connection failed.",
					type: "error",
				});
				await mixpanel.track("Connect Banking", {
					Platform: "TrueLayer",
					"Platform Type": "Open Banking",
					"Connection Status": "Failed",
				});
				setConnectBankAccount(2);
			} finally {
				window.history.replaceState(
					null,
					document.title,
					window.location.pathname
				);
			}
		}

		let code = new URLSearchParams(props.location?.search).get("code");
		let error = new URLSearchParams(props.location?.search).get("error");
		if (
			connectBankAccount === 0 &&
			props.organisation?.id &&
			typeof code === "string"
		) {
			handleOpenBankingAuthorization(props.organisation.id, code);
		}

		if ((error ?? "") === "access_denied") {
			window.history.replaceState(
				null,
				document.title,
				window.location.pathname
			);
			toast({
				text: "Bank account connection cancelled.",
				type: "error",
			});
		}
	}, [connectBankAccount, mixpanel, props, triggerRefreshData]);

	async function openBanking() {
		window.open(process.env.REACT_APP_OPEN_BANKING_URL, "_blank");
		await mixpanel.track("Connect Banking", {
			Platform: "TrueLayer",
			"Platform Type": "Open Banking",
			"Connection Status": "Opened",
		});
	}

	return (
		<div className='w-full p-8 sm:px-0 md:px-0 rounded-lg'>
			<EcommerceConnectionsComponent
				showModal={ecommerceModal}
				toggleModal={toggleEcommerceModal}
				{...props}
			/>
			<EcommercePaymentsComponent
				showModal={paymentsModal}
				toggleModal={togglePaymentsModal}
				{...props}
			/>

			<div className='border-b border-dashed border-custom-gray pb-12'>
				<legend className='font-firma-bold text-custom-dark text-2xl'>
					Connect bank account
				</legend>
				<div className='mt-7'>
					<p className='font-firma-semibold text-custom-gray text-md'>
						What is your bank account data used for?
					</p>
					<p className='font-firma-light text-custom-gray text-md mt-4'>
						Connecting your bank accounts enables us to determine the
						appropriate cash advance amount for your business.
					</p>
				</div>
				{props.connections.fetched && (
					<div className='mt-8'>
						{(props.connections.openBanking ?? 0) > 0 ||
						connectBankAccount === 1 ? (
							<div className='flex text-custom-green justify-between items-center'>
								<h3 className='flex items-center gap-x-2 justify-between text-sm'>
									Connected
									<span className='text-custom-green'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-5 w-5'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='1'
												d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
											/>
										</svg>
									</span>
								</h3>
								<button
									onClick={() => openBanking()}
									className={
										"font-firma-medium tracking-wide text-sm text-custom-dark hover:underline"
									}
								>
									Add another account
								</button>
							</div>
						) : (
							<button
								onClick={() => openBanking()}
								className={
									"font-firma-light tracking-wide px-8 py-4 text-sm rounded-lg border border-custom-dark bg-custom-dark text-white"
								}
							>
								Connect account
							</button>
						)}
					</div>
				)}
			</div>
			<div className='border-b border-dashed border-custom-gray py-12'>
				<legend className='font-firma-bold text-custom-dark text-2xl'>
					Connect e-commerce platform
				</legend>
				<div className='mt-7'>
					<p className='font-firma-semibold text-custom-gray text-md'>
						What is your e-commerce data used for?
					</p>
					<p className='font-firma-light text-custom-gray text-md mt-4'>
						We integrate with your e-commerce accounts to analyse your
						historical sales. Add all your e-commerce accounts so we can provide
						you with the most competitive rate.
					</p>
				</div>
				{props.connections.fetched && (
					<div className='mt-8'>
						{(
							(props.connections.ecommerceCount ?? []).filter(
								(ec: any) => (ec.data ?? 0) > 0
							) ?? 0
						).length > 0 ? (
							<div className='flex text-custom-green justify-between items-center'>
								<h3 className='flex items-center gap-x-2 justify-between text-sm'>
									Connected
									<span className='text-custom-green'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-5 w-5'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='1'
												d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
											/>
										</svg>
									</span>
								</h3>
								<button
									onClick={() => toggleEcommerceModal(true)}
									className={
										"font-firma-medium tracking-wide text-sm text-custom-dark hover:underline"
									}
								>
									Add another account
								</button>
							</div>
						) : (
							<button
								onClick={() => toggleEcommerceModal(true)}
								className={
									"font-firma-light tracking-wide px-8 py-4 text-sm rounded-lg border border-custom-dark bg-custom-dark text-white"
								}
							>
								Connect account
							</button>
						)}
					</div>
				)}
			</div>
			<div className='pt-12'>
				<legend className='font-firma-bold text-custom-dark text-2xl'>
					Connect payment software
				</legend>
				<div className='mt-7'>
					<p className='font-firma-semibold text-custom-gray text-md'>
						What is your payment software used for?
					</p>
					<p className='font-firma-light text-custom-gray text-md mt-4'>
						We integrate with your payment software to analyse your historical
						sales. Connect your payment accounts so we can provide you with the
						most competitive rate.
					</p>
				</div>
				{props.connections.fetched && (
					<div className='mt-8'>
						{(
							(props.connections.paymentsCount ?? []).filter(
								(ec: any) => (ec.data ?? 0) > 0
							) ?? 0
						).length > 0 ? (
							<div className='flex text-custom-green justify-between items-center'>
								<h3 className='flex items-center gap-x-2 justify-between text-sm'>
									Connected
									<span className='text-custom-green'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-5 w-5'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='1'
												d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
											/>
										</svg>
									</span>
								</h3>
								<button
									onClick={() => togglePaymentsModal(true)}
									className={
										"font-firma-medium tracking-wide text-sm text-custom-dark hover:underline"
									}
								>
									Add another account
								</button>
							</div>
						) : (
							<button
								onClick={() => togglePaymentsModal(true)}
								className={
									"font-firma-light tracking-wide px-8 py-4 text-sm rounded-lg border border-custom-dark bg-custom-dark text-white"
								}
							>
								Connect account
							</button>
						)}
					</div>
				)}
			</div>

			<div className='grid text-center gap-y-4 mt-16 sm:max-w-sm md:max-w-sm'>
				<button
					onClick={() => (window.location.href = "/")}
					className='cursor-pointer bg-custom-dark text-white text-center font-firma-light rounded-lg mt-4 w-full py-4 text-lg'
				>
					Continue
				</button>
			</div>
		</div>
	);
}
