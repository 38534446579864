import { cloneElement, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { DashboardLayoutSideBar } from "../components/layout-sidebar";
import { DashboardLayoutMenuBar } from "../components/layout-menu-bar";
import { financeApi, lenkieApi } from "../utils";
import { organisationService } from "../services/organisation";
import { AuthConsumer } from "../providers/auth";
import { LayoutPreloaderWide } from "../components/layout-preloader-wide";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import {
	dashboardDataLoaded,
	setConnectionsData,
	setPersonData,
	setOrganisationData,
	setFinancialAssessments,
} from "../reducers";
import { ECOMMERCE_PLATFORMS, PAYMENT_PLATFORMS } from "../constants";
import { openBankingService } from "../services/open-banking";
import { rutterService } from "../services/rutter";
import { OnboardingConnectionOptions } from "../../definition";
import { MixpanelConsumer } from "providers/mixpanel";

interface Props {
	children?: any;
}

export default function DashboardLayout({ children, ...props }: Props) {
	const state = useAppSelector((state: RootState) => state.dashboard);
	const person = useAppSelector((state: RootState) => state.person);
	const organisation = useAppSelector((state: RootState) => state.organisation);
	const connections = useAppSelector((state: RootState) => state.connections);
	const assessments = useAppSelector(
		(state: RootState) => state.financingAssessments
	);
	const dispatch = useAppDispatch();

	const history = useHistory();

	useEffect(() => {
		async function fetchData() {
			try {
				const [{ data: personData }, { data: organisationData }] =
					await Promise.all([
						await lenkieApi.get("/me"),
						await organisationService.getMyOrganisation(),
					]);

				if (personData) dispatch(setPersonData(personData));
				if (typeof organisationData?.id === "string") {
					const [
						{ data: openBanking } = {} as any,
						ecommerce = [],
						payments = [],
						{ data: financialAssessments },
					] = await Promise.all([
						await openBankingService.getConnectionsCount(organisationData.id),
						await Promise.all(
							ECOMMERCE_PLATFORMS.map((ec: OnboardingConnectionOptions) =>
								rutterService.getConnectionsCount(
									organisationData.id,
									ec.title.replaceAll(" ", "")
								)
							)
						),
						await Promise.all(
							PAYMENT_PLATFORMS.map((ec: OnboardingConnectionOptions) =>
								rutterService.getConnectionsCount(
									organisationData.id,
									ec.title.replaceAll(" ", "")
								)
							)
						),
						await financeApi.get(
							`FinancialAssessments/Seller/${organisationData.id}`
						),
					]);

					dispatch(
						setConnectionsData({
							connections: {},
							connectionsCount: {
								openBanking,
								ecommerce,
								payments,
							},
						})
					);
					dispatch(setOrganisationData(organisationData));
					dispatch(setFinancialAssessments(financialAssessments));
				}

				dispatch(dashboardDataLoaded(true));
			} catch (error: Error | any) {}
		}

		if (!state.loaded) fetchData();
	}, [dispatch, state.loaded, history]);

	if (!state.loaded) return <LayoutPreloaderWide />;

	if (state.loaded && !person?.data?.work_phone_number)
		return <Redirect to='/onboarding/contact' />;

	return (
		<AuthConsumer>
			{(context) => {
				return (
					<MixpanelConsumer>
						{(mixpanel) => {
							return (
								<div id='auth-layout' className='flex w-screen'>
									<DashboardLayoutSideBar
										customClass='bg-custom-dark md:w-84 lg:w-84 min-w-min h-full min-h-screen z-10 sticky top-0'
										logout={context.logout}
										assessments={assessments}
									/>
									<div className='w-full content-wrapper'>
										<DashboardLayoutMenuBar person={person.data} />
										<>
											{cloneElement(
												children,
												Object.assign({}, context, {
													person: person.data,
													organisation: organisation.data,
													connections,
													mixpanel,
												})
											)}
										</>
									</div>
								</div>
							);
						}}
					</MixpanelConsumer>
				);
			}}
		</AuthConsumer>
	);
}

export const authLayoutWrapper = (Component: React.FunctionComponent) => {
	return <DashboardLayout children={<Component />} />;
};
