import numeral from "numeral";
import { createRef, useEffect, useState } from "react";
import { Mixpanel } from "services/mixpanel";
import { RootState, useAppDispatch, useAppSelector } from "store";
import { financeApi } from "utils";
import { toast } from "utils/toast";
import { ModalComponent } from "components/shared/modal";
import {
	financialOptionsDataLoaded,
	setFinancialOptions,
	updateFinancialOption,
} from "../../reducers";

function ViewCashAdvanceDetails(props: any) {
	const option = props.option ?? {};
	const amount = option.amount;
	const percentageValue = option.percentage_fee * option.amount;
	const totalRepayment = percentageValue + option.amount;
	const durationInMonths = Math.ceil(
		amount / option.minimum_monthly_repayment_amount
	);
	return (
		<div className='mdx:p-8 p-4 h-auto'>
			<legend className='text-center font-firma-medium text-xl text-custom-dark'>
				Offer term
			</legend>
			<div className='text-left font-firma-light text-sm text-custom-gray mb-4 mt-4'>
				<button className="text-custom-dark font-firma-medium flex gap-x-2" onClick={props.goBack}><span>&#8592;</span>back</button>
			</div>
			<div className='grid grid-cols-5 sm:mt-4 mt-4 gap-y-2 text-custom-dark'>
				<div className='grid pt-4 sm:pt-2 sm:justify-left col-span-5 gap-y-1'>
					<label className='font-firma-light text-sm'>
						Advance amount
					</label>
					<h1 className='font-firma-bold text-xl sm:text-left'>
						<span className='font-firma-semibold mr-1 text-lg'>£</span>
						{numeral(option.amount).format("0,0")}
					</h1>
				</div>
				<div className='grid pt-4 sm:pt-2 sm:justify-left col-span-5 gap-y-1'>
					<label className='font-firma-light text-sm'>Advance fee</label>
					<h1 className='font-firma-bold text-xl sm:text-left'>
						<span className='font-firma-semibold mr-1 text-lg'>£</span>
						{numeral(percentageValue).format("0,0")}
					</h1>
				</div>
				<div className='grid pt-4 sm:pt-2 sm:justify-left col-span-5 gap-y-1'>
					<label className='font-firma-light text-sm'>
						Total Repayable amount
					</label>
					<h1 className='font-firma-bold text-xl sm:text-left'>
						<span className='font-firma-semibold mr-1 text-lg'>£</span>
						{numeral(totalRepayment).format("0,0")}
					</h1>
				</div>
				<div className='grid pt-4 sm:pt-2 sm:justify-left col-span-5 gap-y-1'>
					<label className='font-firma-light text-sm'>
						Est. repayment time
					</label>
					<h1 className='font-firma-bold text-xl sm:text-left'>
						{numeral(durationInMonths).format("0")} month
						{`${durationInMonths > 1 ? "s" : ""}`}
					</h1>
				</div>
			</div>

			<div className='mt-8'>
				{/* <legend className='font-firma-medium text-custom-dark text-xl'>
					Financing details
				</legend> */}

				<div className='mt-4 text-md max-w-2xl text-custom-dark'>
					<p className='font-firma-light leading-relaxed'>
						Our cash advance is a revenue-based financing product, which means
						that we collect repayments as a fixed percentage of your revenue
						until the Total Repayable Amount is fully paid.
					</p>
					<p className='font-firma-light mt-2'>
						Repayments will go up or down based on your sales revenue, subject
						to a minimum monthly repayment, giving you increased flexibility.
						The total amount you will repay is fixed upfront giving you full
						transparency over the cost of your borrowing.
					</p>
				</div>

				<div className='flex flex-row sm:flex-row gap-4 mt-12 items-center'>
					{!props.hideAcceptTermsButton && (
						<button
							onClick={() => props.accept()}
							className='cursor-pointer text-white font-firma-medium text-sm text-center bg-custom-dark py-4 w-full mb-2 rounded-lg'
						>
							Accept terms
						</button>
					)}
				</div>
			</div>
		</div>
	);
}

export function CashAdvanceFinancingOptionsModal(props: any) {
	const financingOptions = useAppSelector(
		(state: RootState) => state.financingOptions
	);
	const dispatch = useAppDispatch();

	const [financingOption, setFinancingOption] = useState<any | undefined>(
		undefined
	);

	function viewFinancingOption(option: any) {
		setFinancingOption(option);
	}

	useEffect(() => {
		async function fetchGrowthOptions() {
			try {
				const { data } = await financeApi.get(
					`/CashAdvances/Seller/${props.organisation.id}`
				);
				dispatch(setFinancialOptions(data));
			} catch (error) {
			} finally {
				dispatch(financialOptionsDataLoaded(true));
			}
		}

		if (!financingOptions.loaded && props.organisation.id) fetchGrowthOptions();
	}, [dispatch, financingOptions.loaded, props.organisation.id]);

	const hideAcceptTermsButton = () => financingOptions.options.some((o: any) => ["selected"].includes(o.status.id.toLowerCase()))

	async function acceptFinancialOption(params: any) {
		try {
			if (props.instantSettlementOptionActive) {
				return toast({
					text: 'You already have an active cash advance on our growth capital service',
					type: 'error',
				})
			}
			const id = financingOption?.id;
			if (typeof id !== "string") return;
			if (optionSelected() > 0) {
				return toast({
					text: "You are not allowed to request multiple cash advance options.",
					type: "error",
					position: "top",
				});
			}
			const response = await financeApi.put(`/CashAdvances/${id}/Status`, {
				id: "Selected",
			});
			toast({
				text: "Cash advance request successful.",
				type: "info",
				position: "top",
			});
			dispatch(updateFinancialOption(response.data));
			await Mixpanel.track("Cash Advance Option Selected", {
				option_id: id,
			});
		} catch (error) {
			toast({
				text: "An error occured while processing your request.",
				type: "error",
				position: "top",
			});
		}
	}

	function optionSelected(): number {
		return financingOptions.options.filter((op: any) =>
			["selected", "disbursed"].includes(op.status.id.toLowerCase())
		).length;
	}

	const modalRef: any = createRef();

	function closeModal() {
		modalRef.current.scrollTop = 0;
		props.toggleModal(false);
		setFinancingOption(undefined);
	}

	return (
		<ModalComponent
			show={props.show}
			className='xs:w-11/12 sm:w-8/12 md:w-8/12 max-w-sm w-full top-10 absolute mdx:translate-x-2/4 sm:absolute md:absolute lg:absolute align-top mb-20 overflow-y-scroll'
			style={{
				left: "50%",
				transform: "translateX(-50%)",
				maxHeight: "80vh",
			}}
			modalRef={modalRef}
			hideModal={() => props.toggleModal(false)}
		>
			{financingOption ? (
				<ViewCashAdvanceDetails hideAcceptTermsButton={hideAcceptTermsButton} option={financingOption} accept={acceptFinancialOption} goBack={() => setFinancingOption(undefined)} />
			) : (
				<div className='mdx:p-8 p-8 h-auto'>
					{financingOptions.options.length > 0 ? (
						<>
							<legend className='text-center font-firma-medium text-xl text-custom-dark'>
								Available cash advance options
							</legend>
							<legend className='text-center font-firma-light text-sm text-custom-gray mb-4 mt-1'>
								Based on your revenue, we have pre-approved you for the
								following offers. Please choose the option that best suits your
								business needs.
							</legend>
						</>
					) : (
						<div className='mb-8'>
							<legend className='text-center font-firma-medium text-lg text-custom-dark'>
								No cash advance offers available at this time.
							</legend>
						</div>
					)}
					<div className='w-full mt-4'>
						<div className='sm:px-0 md:px-0 lg:px-0 px-0'>
							<div className='sm:mt-16 mt-8 grid gap-8 max-w-6xl divide-y divider'>
								{financingOptions.options
									.slice()
									.sort((a: any, b: any) => b.amount - a.amount)
									.map((n: any, i) => {
										const percentageFee = n.percentage_fee;
										const percentageValue = percentageFee * n.amount;
										const totalRepayment = percentageValue + n.amount;
										return (
											<div
												key={i}
												className={`lg:col-span-4 md:col-span-6 col-span-6 grid gap-y-8 ${
													i !== 0 ? "pt-8" : ""
												}`}
											>
												<div className='bg-custom-dark-negative-y p-4 rounded-lg flex justify-center flex-col gap-y-1'>
													<h4 className='font-firma-light'>
														Total advance amount
													</h4>
													<h1 className='font-firma-bold text-2xl text-custom-dark'>
														<span className='font-firma-semibold mr-1 text-2xl text-custom-dark'>
															£
														</span>
														{numeral(n.amount).format("0, 0")}
													</h1>
												</div>
												<div className='grid grid-cols-3 justify-center items-center flex-col gap-y-3 text-custom-dark'>
													<h4 className='font-firma-light col-span-2 text-sm'>
														Revenue repayment rate:
													</h4>
													<h1 className='font-firma-bold col-span-1 text-right'>
														{numeral(n.repayment_percentage * 100).format("0")}%
													</h1>
													<h4 className='font-firma-light col-span-2 text-sm'>
														Flat fee:
													</h4>
													<h1 className='font-firma-bold col-span-1 text-right'>
														{numeral(n.percentage_fee).format("0,0%")}
													</h1>
													<h4 className='font-firma-light col-span-2 text-sm'>
														Total repayment:
													</h4>
													<h1 className='font-firma-bold col-span-1 text-right'>
														<span className='font-firma-semibold mr-1 text-sm'>
															£
														</span>
														{numeral(totalRepayment).format("0,0")}
													</h1>
												</div>
												{n.status.id !== "Selected" && (
													<button
														className='text-center bg-custom-dark hover:bg-custom-darker font-firma-light rounded-lg text-white p-4 sm:px-8 text-md'
														onClick={() => viewFinancingOption(n)}
													>
														View details
													</button>
												)}
												{n.status.id === "Selected" && (
													<button
														className='text-center bg-custom-green font-firma-light rounded-lg text-white p-4 sm:px-8 text-sm'
														onClick={() => viewFinancingOption(n)}
													>
														<h3 className='font-firma-light flex items-center justify-center gap-x-4 text-md'>
															Selected
															<span className='text-white'>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	className='h-5 w-5'
																	fill='none'
																	viewBox='0 0 24 24'
																	stroke='currentColor'
																>
																	<path
																		strokeLinecap='round'
																		strokeLinejoin='round'
																		strokeWidth='1.5'
																		d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
																	/>
																</svg>
															</span>
														</h3>
													</button>
												)}
											</div>
										);
									})}
							</div>
						</div>
					</div>
					<div className='mt-4'>
						<button
							className='cursor-pointer text-gray-600 font-firma-medium text-sm text-center bg-custom-yellow py-4 w-full mb-2 rounded-lg'
							onClick={closeModal}
						>
							Close
						</button>
					</div>
				</div>
			)}
		</ModalComponent>
	);
}
