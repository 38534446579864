import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Person } from "../models/person";

export interface PersonState {
	fetched: boolean;
	data: Person;
}

const initialState: PersonState = {
	fetched: false,
	data: {
		href: undefined,
		id: undefined,
		version: undefined,
		name: undefined,
		given_name: undefined,
		family_name: undefined,
		work_phone_number: undefined,
		work_email_address: undefined,
		organisations: [],
	},
};

export const personSlice = createSlice({
	name: "person",
	initialState,
	reducers: {
		setPersonData: (
			state,
			action: PayloadAction<{ [key: string]: any } | any>
		) => {
			state.fetched = true;
			state.data = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setPersonData } = personSlice.actions;

export default personSlice.reducer;
