import { useLocation } from "react-router-dom";
import { FullLogo } from "../components/shared/logo-full";

export function PageNotFound() {
	let location = useLocation();

	return (
		<div className='flex flex-col gap-y-4 items-center justify-center min-h-screen text-gray-600 font-firma-medium'>
			<FullLogo />
			<h3>
				Page not found for <code>{location.pathname}</code>
			</h3>
		</div>
	);
}
