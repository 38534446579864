import { ReactElement, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { GetStartedDetailsComponent } from "../components/get-started-details";
import { FullLogo } from "../components/shared/logo-full";
import { AuthConsumer } from "../providers/auth";
import { Mixpanel } from "../services/mixpanel";
import userManager from "../utils/user-manager";

declare let gtag: Function;

export const GetStarted = (): ReactElement => {
	const [user, setUser] = useState<any>({ fetched: false, data: null });

	const fetchUser = useCallback(async () => {
		const _user = await userManager.getUser();
		setUser({ data: _user, fetched: true });
	}, []);

	useEffect(() => {
		(async function () {
			await Mixpanel.track("View Get Started");
		})();

		if (!user.fetched) {
			fetchUser();
		}

		if (process.env.REACT_APP_ENABLE_GA === "true") {
			gtag("event", "View Get Started");
		}
	}, [fetchUser, user]);

	const [modal, setModal] = useState(false);

	async function navigateToOnboarding(signinRedirect: Function): Promise<void> {
		if (!(localStorage.getItem("pqa") === "done")) {
			return setModal(true);
		}
		await signinRedirect();
		await Mixpanel.track("Redirect to sign in");
	}

	return (
		<AuthConsumer>
			{(consumerArgs) => {
				const { signinRedirect } = consumerArgs;
				return (
					<>
						<Helmet>
							<title>Get started | Lenkie</title>
						</Helmet>
						<GetStartedDetailsComponent
							toggleModal={() => setModal(!modal)}
							showModal={modal}
							mixpanel={Mixpanel}
							authenticate={signinRedirect}
						/>
						<section className='grid grid-cols-1 lg:grid-cols-2 min-h-screen h-full w-full mx-auto'>
							<div className='col-span-1 grid h-fit'>
								<div className='content max-w-3xl w-full mx-auto px-8 sm:px-0'>
									<div className='w-full mx-auto py-8'>
										<FullLogo />
									</div>
									<div className='py-28 pt-20 md:pt-20 md:pb-40'>
										<legend className='text-custom-dark text-lead mx-auto font-firma-bold'>
											Fast, Flexible and Transparent funding for your business
										</legend>

										<h4 className='mt-34 text-lg tracking-wide font-firma text-custom-dark'>
											Speedy funding from £5k to £250k to fund marketing,
											inventory and growth without giving up equity.
										</h4>

										{!user.data && user.fetched ? (
											<div className='mt-49 lg:hidden grid'>
												<button
													onClick={() => navigateToOnboarding(signinRedirect)}
													className='bg-custom-orange text-custom-dark px-8 py-4 flex items-center gap-x-4 w-fit rounded-lg text-lg hover:bg-custom-yellow font-firma-medium'
												>
													Get started
													<span>
														<svg
															width='18'
															height='18'
															viewBox='0 0 24 25'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M5 12.5H19'
																stroke='#1A2D5B'
																strokeWidth='2'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
															<path
																d='M12 5.5L19 12.5L12 19.5'
																stroke='#1A2D5B'
																strokeWidth='2'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
														</svg>
													</span>
												</button>

												<p className='sm:mt-8 mt-4 font-firma-light text-custom-dark'>
													This will take about 2-5 minutes of your time
												</p>

												<Link
													to='/'
													className='text-sm font-firma-light mt-8 underline text-custom-dark'
												>
													Already have an account?
												</Link>
											</div>
										) : (
											<div className='mt-49 lg:hidden grid'>
												<Link
													to="/"
													className='bg-custom-orange text-custom-dark px-8 py-4 flex items-center gap-x-4 w-fit rounded-lg text-lg hover:bg-custom-yellow font-firma-medium'
												>
													Enter dashboard
													<span>
														<svg
															width='18'
															height='18'
															viewBox='0 0 24 25'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M5 12.5H19'
																stroke='#1A2D5B'
																strokeWidth='2'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
															<path
																d='M12 5.5L19 12.5L12 19.5'
																stroke='#1A2D5B'
																strokeWidth='2'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
														</svg>
													</span>
												</Link>
											</div>
										)}

										<div
											className='sm:mt-16 mt-8 w-full p-4 sm:p-6 rounded-lg'
											style={{ maxWidth: "226px", background: "#F5FDFF" }}
										>
											<p className='font-firma-semibold'>
												Have questions to ask?
											</p>
											<p>We’re here to help</p>

											<div className='mt-8 grid w-fit text-gray-500'>
												<a href='tel:+447939498942' className='underline'>
													+44 7939 498942
												</a>
												<a href='mailto:hello@lenkie.com' className='underline'>
													hello@lenkie.com
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-span-1 bg-custom-dark pb-20 md:pt-x212 lg:pt-x212 pt-24 px-8'>
								<div className='content w-full mx-auto max-w-2xl'>
									<legend className='text-custom-orange text-3.5xl font-firma-bold'>
										Unlock capital in 3 easy steps
									</legend>

									<h4 className='mt-4 sm:text-xl text-white text-lg tracking-wide font-firma-light'>
										To be able to get the best offers, we will need to verify
										some information about your company and sales.
									</h4>

									<h3 className='mt-52 text-md text-white font-firma-bold tracking-wider'>
										What we will need:
									</h3>

									<ul className='mt-35 grid gap-y-x25'>
										<li className='flex items-center gap-x-4'>
											<span className='text-custom-orange'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													className='h-7 w-7'
													viewBox='0 0 20 20'
													fill='currentColor'
												>
													<path
														fillRule='evenodd'
														d='M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z'
														clipRule='evenodd'
													/>
												</svg>
											</span>
											<p className='sm:text-xl text-white font-firma-light'>
												Sign up with some basic company details
											</p>
										</li>
										<li className='flex items-center gap-x-4'>
											<span className='text-custom-orange'>
												<svg
													className='h-7 w-7 fill-current'
													width='20'
													height='20'
													viewBox='0 0 24 24'
													fill='currentColor'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M19 15C16.79 15 15 16.79 15 19C15 19.75 15.21 20.46 15.58 21.06C16.27 22.22 17.54 23 19 23C20.46 23 21.73 22.22 22.42 21.06C22.79 20.46 23 19.75 23 19C23 16.79 21.21 15 19 15ZM21.07 18.57L18.94 20.54C18.8 20.67 18.61 20.74 18.43 20.74C18.24 20.74 18.05 20.67 17.9 20.52L16.91 19.53C16.62 19.24 16.62 18.76 16.91 18.47C17.2 18.18 17.68 18.18 17.97 18.47L18.45 18.95L20.05 17.47C20.35 17.19 20.83 17.21 21.11 17.51C21.39 17.81 21.37 18.28 21.07 18.57Z'
														fill='currentColor'
													/>
													<path
														d='M22 7.55039V8.00039C22 8.55039 21.55 9.00039 21 9.00039H3C2.45 9.00039 2 8.55039 2 8.00039V7.54039C2 5.25039 3.85 3.40039 6.14 3.40039H17.85C20.14 3.40039 22 5.26039 22 7.55039Z'
														fill='currentColor'
													/>
													<path
														d='M2 11.5002V16.4602C2 18.7502 3.85 20.6002 6.14 20.6002H12.4C12.98 20.6002 13.48 20.1102 13.43 19.5302C13.29 18.0002 13.78 16.3402 15.14 15.0202C15.7 14.4702 16.39 14.0502 17.14 13.8102C18.39 13.4102 19.6 13.4602 20.67 13.8202C21.32 14.0402 22 13.5702 22 12.8802V11.4902C22 10.9402 21.55 10.4902 21 10.4902H3C2.45 10.5002 2 10.9502 2 11.5002ZM8 17.2502H6C5.59 17.2502 5.25 16.9102 5.25 16.5002C5.25 16.0902 5.59 15.7502 6 15.7502H8C8.41 15.7502 8.75 16.0902 8.75 16.5002C8.75 16.9102 8.41 17.2502 8 17.2502Z'
														fill='currentColor'
													/>
												</svg>
											</span>
											<p className='sm:text-xl text-white font-firma-light'>
												Connect sales and bank accounts
											</p>
										</li>
										<li className='flex items-center gap-x-4'>
											<span className='text-custom-orange'>
												<svg
													className='h-7 w-7 fill-current'
													width='18'
													height='18'
													viewBox='0 0 24 24'
													fill='currentColor'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M5 15C4.06 15 3.19 15.33 2.5 15.88C1.58 16.61 1 17.74 1 19C1 19.75 1.21 20.46 1.58 21.06C2.27 22.22 3.54 23 5 23C6.01 23 6.93 22.63 7.63 22C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15ZM7.07 18.57L4.94 20.54C4.8 20.67 4.61 20.74 4.43 20.74C4.24 20.74 4.05 20.67 3.9 20.52L2.91 19.53C2.62 19.24 2.62 18.76 2.91 18.47C3.2 18.18 3.68 18.18 3.97 18.47L4.45 18.95L6.05 17.47C6.35 17.19 6.83 17.21 7.11 17.51C7.39 17.81 7.37 18.29 7.07 18.57Z'
														fill='currentColor'
													/>
													<path
														d='M14.85 3.9498V7.7498H13.35V3.9498C13.35 3.6798 13.11 3.5498 12.95 3.5498C12.9 3.5498 12.85 3.5598 12.8 3.5798L4.87 6.56981C4.34 6.7698 4 7.2698 4 7.8398V8.5098C3.09 9.1898 2.5 10.2798 2.5 11.5098V7.8398C2.5 6.6498 3.23 5.5898 4.34 5.1698L12.28 2.1698C12.5 2.0898 12.73 2.0498 12.95 2.0498C13.95 2.0498 14.85 2.8598 14.85 3.9498Z'
														fill='currentColor'
													/>
													<path
														d='M21.4997 14.5V15.5C21.4997 15.77 21.2897 15.99 21.0097 16H19.5497C19.0197 16 18.5397 15.61 18.4997 15.09C18.4697 14.78 18.5897 14.49 18.7897 14.29C18.9697 14.1 19.2197 14 19.4897 14H20.9997C21.2897 14.01 21.4997 14.23 21.4997 14.5Z'
														fill='currentColor'
													/>
													<path
														d='M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V13.29C2.5 13.67 2.9 13.91 3.26 13.79C3.82 13.6 4.41 13.5 5 13.5C8.03 13.5 10.5 15.97 10.5 19C10.5 19.72 10.31 20.51 10.01 21.21C9.85 21.57 10.1 22 10.49 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.3 14.77 17.54 14.03 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H9C8.59 12.75 8.25 12.41 8.25 12C8.25 11.59 8.59 11.25 9 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z'
														fill='currentColor'
													/>
												</svg>
											</span>
											<p className='sm:text-xl text-white font-firma-light'>
												Select and receive funding
											</p>
										</li>
									</ul>

									{!user.data && user.fetched ? (<div className='mt-x37 lg:grid hidden'>
										<button
											onClick={() => navigateToOnboarding(signinRedirect)}
											className='bg-custom-orange text-custom-dark px-8 py-4 flex items-center gap-x-4 w-fit rounded-lg text-lg hover:bg-custom-yellow font-firma-medium'
										>
											Get started
											<span>
												<svg
													width='18'
													height='18'
													viewBox='0 0 24 25'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M5 12.5H19'
														stroke='#1A2D5B'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M12 5.5L19 12.5L12 19.5'
														stroke='#1A2D5B'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</span>
										</button>

										<p className='sm:mt-8 mt-4 font-firma-light text-white'>
											This will take about 2-5 minutes of your time
										</p>

										<Link
											to='/'
											className='text-sm font-firma-light mt-8 underline text-custom-orange'
										>
											Already have an account?
										</Link>
									</div>
									) : <div className='mt-x37 lg:grid hidden'>
										<Link
											to="/"
											className='bg-custom-orange text-custom-dark px-8 py-4 flex items-center gap-x-4 w-fit rounded-lg text-lg hover:bg-custom-yellow font-firma-medium'
										>
											Enter dashboard
											<span>
												<svg
													width='18'
													height='18'
													viewBox='0 0 24 25'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M5 12.5H19'
														stroke='#1A2D5B'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M12 5.5L19 12.5L12 19.5'
														stroke='#1A2D5B'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</span>
										</Link>
									</div> }
								</div>
							</div>
						</section>
					</>
				);
			}}
		</AuthConsumer>
	);
};
