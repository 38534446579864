import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { lenkieApi } from "../../utils";

export const GrowthCapitalOptions = (props: any) => {
	const [fetched, setFetched] = useState(false);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		async function fetchGrowthOptions() {
			try {
				const { data } = await lenkieApi.get(
					`/CashAdvanceFinancingOptions/${props.organisation.id}`
				);
				setOptions(data);
			} catch (error) {
			} finally {
				setFetched(true);
			}
		}

		if (!fetched && props.organisation.id) fetchGrowthOptions();
	}, [props, fetched]);

	return (
		<div className='w-full mt-16 lg:mt-8'>
			<div className='sm:px-0 md:px-0 lg:px-0 px-0'>
				<legend className='font-firma-bold text-custom-dark text-3xl'>
					Financing options
				</legend>
				<p className='font-firma-light sm:w-1/2'>
					Based on our assessment of your business performance, your business
					has qualified for the following financing options:
				</p>
				<div className='sm:mt-16 mt-8 grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-8'>
					{options.map((n, i) => (
						<div
							key={i}
							className='lg:col-span-4 md:col-span-6 sm:p-8 p-4 rounded-lg border border-gray-100 grid gap-y-8 hover:shadow-sm'
						>
							<div className='bg-custom-dark-lighter-2 p-4 rounded-lg flex justify-center flex-col gap-y-1'>
								<h4 className='font-firma-light'>Total advance amount:</h4>
								<h1 className='font-firma-bold text-2xl'>
									<sup className='font-firma-semibold mr-1 text-lg'>£</sup>
									50,000
								</h1>
							</div>
							<div className='grid grid-cols-3 justify-center items-center flex-col gap-y-3'>
								<h4 className='font-firma-light col-span-2 text-sm'>
									Revenue repayment rate:
								</h4>
								<h1 className='font-firma-bold col-span-1 text-right'>25%</h1>
								<h4 className='font-firma-light col-span-2 text-sm'>
									Cash advance fee:
								</h4>
								<h1 className='font-firma-bold col-span-1 text-right'>
									<sup className='font-firma-semibold mr-1 text-xs'>£</sup>4,000
								</h1>
								<h4 className='font-firma-light col-span-2 text-sm'>
									Total repayment:
								</h4>
								<h1 className='font-firma-bold col-span-1 text-right'>
									<sup className='font-firma-semibold mr-1 text-xs'>£</sup>
									54,100
								</h1>
							</div>
							<NavLink
								to={`/growth-capital/options/${n}`}
								className='text-center bg-custom-dark font-firma-light rounded-lg text-white p-4 sm:px-8 text-sm'
							>
								View details
							</NavLink>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
