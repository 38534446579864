import { Route, Switch } from "react-router-dom";
import DefaultComingSoonComponent from "../components/coming-soon";
import { OnboardingBusinessComponent } from "../components/pages/onboarding/business";
import { OnboardingConnectionsComponent } from "../components/pages/onboarding/connections";
import { OnboardingContactComponent } from "../components/pages/onboarding/contact";
import { authLayoutWrapper } from "../layouts/auth";
import { onboardingLayoutWrapper } from "../layouts/onboarding";
import { PartnerLayout } from "../layouts/partner";

import {
	Callback,
	Logout,
	LogoutCallback,
	SilentRenew,
	Home,
	GrowthCapital,
	GrowthCapitalOptions,
	ViewGrowthCapitalOption,
	GetStarted,
	PageNotFound,
	StripeConnectionsCallback,
	Connections,
} from "../views";
import { ProtectedRoute } from "./protected";

export const Routes = (
	<Switch>
		<Route exact path='/get-started' component={GetStarted} />

		{/* Authentication */}
		<Route exact path='/callback' component={Callback} />
		<Route exact path='/logout' component={Logout} />
		<Route exact path='/logout/callback' component={LogoutCallback} />
		<Route exact path='/silent-renew' component={SilentRenew} />

		{/* Protected */}
		<ProtectedRoute
			exact
			path='/onboarding/contact'
			component={onboardingLayoutWrapper(OnboardingContactComponent)}
		/>
		<ProtectedRoute
			exact
			path='/onboarding/business'
			component={onboardingLayoutWrapper(OnboardingBusinessComponent)}
		/>
		<ProtectedRoute
			exact
			path='/onboarding/connections'
			component={onboardingLayoutWrapper(OnboardingConnectionsComponent)}
		/>
		<Route
			exact
			path='/onboarding/connections/stripe'
			component={StripeConnectionsCallback}
		/>

		<ProtectedRoute exact path='/' component={authLayoutWrapper(Home)} />
		<ProtectedRoute
			exact
			path='/connections'
			component={authLayoutWrapper(Connections)}
		/>
		<ProtectedRoute
			exact
			path='/repayments'
			component={authLayoutWrapper(DefaultComingSoonComponent)}
		/>
		<ProtectedRoute
			exact
			path='/settings'
			component={authLayoutWrapper(DefaultComingSoonComponent)}
		/>
		<ProtectedRoute
			exact
			path='/wallet'
			component={authLayoutWrapper(DefaultComingSoonComponent)}
		/>

		{/* Growth Capital */}
		<ProtectedRoute
			exact
			path='/growth-capital'
			component={authLayoutWrapper(GrowthCapital)}
		/>
		<ProtectedRoute
			exact
			path='/growth-capital/options'
			component={authLayoutWrapper(GrowthCapitalOptions)}
		/>
		<ProtectedRoute
			exact
			path='/growth-capital/options/:id'
			component={authLayoutWrapper(ViewGrowthCapitalOption)}
		/>

		<Route path='/:platform' component={PartnerLayout} />
		<Route path='*' component={PageNotFound} />
	</Switch>
);
