import { createRef } from "react";
import { ModalComponent } from "components/shared/modal";

export function InstantPayoutOptionsModal(props: any) {
	const modalRef: any = createRef();

	function closeModal() {
		modalRef.current.scrollTop = 0;
		props.toggleModal(false);
	}

	return (
		<ModalComponent
			show={props.show}
			className='xs:w-11/12 sm:w-11/12 md:w-8/12 max-w-md w-full top-10 absolute mdx:translate-x-2/4 sm:absolute md:absolute lg:absolute align-top mb-20 overflow-y-scroll'
			style={{
				left: "50%",
				transform: "translateX(-50%)",
				maxHeight: "80vh",
			}}
			modalRef={modalRef}
			hideModal={() => props.toggleModal(false)}
		>
			<div className='mdx:p-8 p-8 h-auto'>
				
				<div className='w-full'>
					{props.children}
				</div>
				<div className='mt-4'>
					<button
						className='cursor-pointer text-gray-600 font-firma-medium text-md text-center bg-custom-yellow py-4 w-full mb-2 rounded-lg'
						onClick={closeModal}
					>
						Close
					</button>
				</div>
			</div>
		</ModalComponent>
	);
}
