import { ReactElement, useState } from "react";

interface AlertConnectionsProps {
	children: ReactElement;
	close?: boolean;
}

export function AlertConnectionsComponent(props: AlertConnectionsProps|any) {
	const [, setShowPanel] = useState(localStorage.getItem("cpn") !== "hide");

	function hideCPN(): void {
		setShowPanel(false);
		localStorage.setItem("cpn", "hide");
	}

	return (
		<div className='max-w-6xl col-span-12 p-4 md:p-6 lg:p-8 bg-custom-orange-lighter text-custom-dark rounded-xl h-fit w-full flex gap-x-4 items-start'>
			<svg
				width='20'
				height='20'
				viewBox='0 0 24 24'
				fill='currentColor'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM12 19.66C11.062 19.66 10.42 18.937 10.42 18C10.42 17.036 11.089 16.34 12 16.34C12.963 16.34 13.58 17.036 13.58 18C13.58 18.938 12.963 19.66 12 19.66ZM12.622 13.321C12.383 14.136 11.63 14.15 11.379 13.321C11.09 12.365 10.063 8.736 10.063 6.379C10.063 3.269 13.954 3.254 13.954 6.379C13.953 8.75 12.871 12.473 12.622 13.321Z'
					fill='currentColor'
				/>
			</svg>

			{props.children}
			{false && (
				<button onClick={hideCPN}>
					<svg
						width='18'
						height='18'
						viewBox='0 0 24 24'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M18 6L6 18'
							stroke='white'
							strokeWidth='1'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M6 6L18 18'
							stroke='white'
							strokeWidth='1'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</button>
			)}
		</div>
	);
}
