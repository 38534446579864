import { AuthConsumer } from "../../providers/auth";

export const LogoutCallback = () => (
	<AuthConsumer>
		{({ signoutRedirectCallback }: any) => {
			signoutRedirectCallback();
			return <span>logout calback</span>;
		}}
	</AuthConsumer>
);
