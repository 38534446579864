import dayjs from "dayjs";
import numeral from "numeral";
import ReactTooltip from "react-tooltip";

// function randomIntFromInterval(min: number, max: number) {
// 	// min and max included
// 	return Math.floor(Math.random() * (max - min + 1) + min);
// }

export function TableHomeHistoryGrowthCapitalComponent({
	tableData = [],
	repaymentPercentage = 0,
	borrowedAmount = 0,
}: any) {
	let TABLE_DATA = tableData
		.sort((a: any, b: any) =>
			dayjs(a.payment_date).isBefore(dayjs(b.payment_date)) ? 1 : -1
		)
		.map((td: any, i: number) => {
			let repaymentTotal = tableData
				.map((_td: any, i: number) => {
					return _td.amount;
				})
				.splice(i, tableData.length + 1)
				.reduce((a: number, b: number) => a + b, 0);

			const outstanding = borrowedAmount - repaymentTotal;

			return {
				...td,
				outstanding: outstanding < 0 ? 0 : outstanding,
				repaymentTotal,
			};
		});

	return (
		<div className='grid col-span-12 mt-2 items-center'>
			<ul className='text-center md:grid lg:grid grid-cols-6 items-center text-sm p-4 border-t border-l border-r border-gray-200 md:rounded-t-xl lg:rounded-t-xl hidden'>
				<li className='hidden text-center md:block lg:block'>
					<span
						className='text-custom-dark font-firma-semibold text-sm cursor-help'
						data-tip
						data-for='due-date'
					>
						Repayment Date
						<ReactTooltip id='due-date' className='bg-custom-dark'>
							<span className='text-xs font-firma-light'>
								Date when repayment amounts
								<br />
								are calculated.
							</span>
						</ReactTooltip>
					</span>
				</li>
				<li className='text-sm text-custom-dark font-firma-semibold'>
					<span
						className='text-custom-dark font-firma-semibold text-sm cursor-help'
						data-tip
						data-for='revenue'
					>
						Revenue (£)
						<ReactTooltip id='revenue' className='bg-custom-dark'>
							<span className='text-xs font-firma-light'>
								Refers to revenue generated between
								<br />
								the current and previous repayment dates.
							</span>
						</ReactTooltip>
					</span>
				</li>
				<li className='text-sm text-custom-dark font-firma-semibold'>
					<span
						className='text-custom-dark font-firma-semibold text-sm cursor-help'
						data-tip
						data-for='revenue-amount'
					>
						Repayment Amount (£)
						<ReactTooltip id='revenue-amount' className='bg-custom-dark'>
							<span className='text-xs font-firma-light'>
								Revenue multiplied by the agreed revenue
								<br />
								repayment percentage.
							</span>
						</ReactTooltip>
					</span>
				</li>
				<li className='text-sm text-custom-dark font-firma-semibold'>
					<span
						className='text-custom-dark font-firma-semibold text-sm cursor-help'
						data-tip
						data-for='total-repayment-amount'
					>
						Total Repaid (£)
						<ReactTooltip
							id='total-repayment-amount'
							className='bg-custom-dark'
						>
							<span className='text-xs font-firma-light'>
								Cumulative total amount of repayments
								<br />
								received.
							</span>
						</ReactTooltip>
					</span>
				</li>
				<li className='text-sm text-custom-dark font-firma-semibold'>
					<span
						className='text-custom-dark font-firma-semibold text-sm cursor-help'
						data-tip
						data-for='total-repayment-balance'
					>
						Total Outstanding (£)
						<ReactTooltip
							id='total-repayment-balance'
							className='bg-custom-dark'
						>
							<span className='text-xs font-firma-light'>
								The total outstanding balance repayable.
							</span>
						</ReactTooltip>
					</span>
				</li>
				<li className='text-sm text-custom-dark font-firma-semibold'>
					<span
						className='text-custom-dark font-firma-semibold text-sm cursor-help'
						data-tip
						data-for='status'
					>
						Status
						<ReactTooltip
							id='status'
							className='bg-custom-dark'
						>
							<span className='text-xs font-firma-light'>
								Pending refers to repayments that<br />are currently being collected.
							</span>
						</ReactTooltip>
					</span>
				</li>
			</ul>

			<div className='grid border border-gray-200 rounded-b-xl overflow-hidden rounded-t-xl md:rounded-t-none lg:rounded-t-none'>
				{TABLE_DATA.map((td: any, i: number) => {
					return (
						<ul
							className={
								"grid grid-cols-6 items-center px-4 py-6 md:py-4 lg:py-4 gap-4 cursor-pointer text-center hover:bg-gray-50 " +
								`${
									i !== tableData.length - 1 ? "border-b border-gray-200" : ""
								} ${i % 2 ? "bg-gray-50" : ""}`
							}
							key={i}
						>
							<li className=' md:block lg:block text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma text-sm'>
								<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
									Repayment Date
								</p>
								{dayjs(td.payment_date).format("DD/MM/YYYY")}
							</li>
							<li className='text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma-medium text-sm'>
								<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
									Revenue (£)
								</p>
								{numeral(td.actual_revenue).format("0,0.00")}
							</li>
							<li className='text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma-medium text-sm'>
								<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
									Repayment Amount
								</p>
								{numeral(td.amount).format("0,0.00")}
							</li>
							<li className='text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma-medium text-sm'>
								<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
									Total Repaid Amount
								</p>
								{numeral(td.repaymentTotal).format("0,0.00")}
							</li>
							<li className='text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma-medium text-sm'>
								<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
									Total Repaid Amount
								</p>
								{numeral(td.outstanding).format("0,0.00")}
							</li>
							{td.payment_status.id === "Paid" ? (
								<li className='text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma-normal text-sm'>
									<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
										Status
									</p>
									<span className='text-xs font-firma hidden sm:block md:block lg:block rounded-lg py-1 px-4 uppercase text-green-700 bg-green-200 border border-green-200 w-fit md:mx-auto lg:mx-auto'>
										paid
									</span>
									<span className='text-sm block sm:hidden md:hidden lg:hidden rounded-lg text-green-700 uppercase'>
										paid
									</span>
								</li>
							) : (
								<li className='text-custom-dark col-span-3 md:col-span-1 lg:col-span-1 text-left md:text-center lg:text-center font-firma-normal text-sm'>
									<p className='text-sm text-custom-dark font-firma-light mb-1 block md:hidden lg:hidden'>
										Status
									</p>
									<span className='text-xs font-firma hidden sm:block md:block lg:block rounded-lg py-1 px-4 uppercase text-yellow-700 bg-yellow-200 border border-yellow-200 w-fit md:mx-auto lg:mx-auto'>
										pending
									</span>
									<span className='text-sm block sm:hidden md:hidden lg:hidden rounded-lg text-yellow-700 uppercase'>
										pending
									</span>
								</li>
							)}
						</ul>
					);
				})}
			</div>
		</div>
	);
}
