import { createUserManager } from "redux-oidc";
import { IDENTITY_CONFIG, METADATA_OIDC } from "../constants";

const userManager = createUserManager({
	...IDENTITY_CONFIG,
	metadata: {
		...METADATA_OIDC,
	},
});

export default userManager;
