import { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { GeneralPageTabComponent } from "../components/home-tab";
import { HomeGrowthCapitalComponent } from "../components/pages/home/growth-capital";
import { RootState, useAppSelector } from "../store";
import { HomeInstantPayoutComponent } from "components/pages/home/instant-payout";

export const Home = (props: any) => {
	const [summary] = useState({} as any);
	const history = useHistory();
	const hash = history.location.hash.replaceAll("#", "");
	const [tab, setTab] = useState(hash === "" ? "growth-capital" : hash);

	function switchTab(hash: string) {
		hash = hash.replaceAll("#", "");
		history.push({ pathname: "", hash });
		setTab(hash);
	}

	const financingAssessments = useAppSelector(
		(state: RootState) => state.financingAssessments
	);
	const financingAssessmentsStatus = financingAssessments.assessments.some(
		(assessment) => assessment.is_approved
	);

	const { assessments } = financingAssessments;
	const cashAdvanceOptions = assessments.map((a: any) => a.available_cash_advance_options).flat(1);
	const cashAdvanceOptionActive = cashAdvanceOptions.some((i) => ["Selected", "Disbursed"].includes(i.status.id));
	const instantSettlementOptions = assessments.map((a: any) => a.available_instant_settlement_options).flat(1);
	const instantSettlementOptionActive = instantSettlementOptions.some((i) => ["Selected", "Disbursed"].includes(i.status.id))

	return (
		<>
			<Helmet>
				<title>Home | Lenkie</title>
			</Helmet>
			<GeneralPageTabComponent
				active={tab}
				name={props?.person?.given_name + " 👋🏼"}
				switchTab={switchTab}
				hasMenu={true}
				welcome={true}
			/>
			<div className='md:p-14 lg:p-14 p-4 h-auto'>
				{tab === "growth-capital" && (
					<HomeGrowthCapitalComponent
						summary={summary}
						status={financingAssessmentsStatus}
						connections={props.connections}
						assessments={financingAssessments.assessments}
						organisation={props.organisation}
						instantSettlementOptionActive={instantSettlementOptionActive}
					/>
				)}
				{tab === "instant-payout" && (
					<HomeInstantPayoutComponent
						assessments={financingAssessments.assessments}
						organisation={props.organisation}
						summary={summary}
						status={financingAssessmentsStatus}
						connections={props.connections}
						cashAdvanceOptionActive={cashAdvanceOptionActive}
					/>
				)}
			</div>
		</>
	);
};
