import { AuthConsumer } from "../../providers/auth";

export const SilentRenew = () => (
	<AuthConsumer>
		{({ signinSilentCallback }) => {
			signinSilentCallback();
			return <span>silent renew</span>;
		}}
	</AuthConsumer>
);
