import { AxiosInstance, AxiosResponse } from "axios";
import { rutterApi } from "../utils";

export class Service {
	constructor(private http: AxiosInstance) {}

	public async exchangeToken(
		authCode: string,
		sellerId: string
	): Promise<AxiosResponse> {
		return await this.http.post(`/Connections/${sellerId}`, null, {
			params: { authCode },
		});
	}

	public async getConnection(
		sellerId: string,
		platform?: string,
		title?: string
	): Promise<AxiosResponse | any> {
		try {
			const { data } = await this.http.get<any>(
				`/Connections/${sellerId}?platform=${platform ?? ""}`
			);
			return {
				platform,
				data,
				status: true,
				title,
			};
		} catch (error) {
			return { platform, data: null, status: false, title };
		}
	}

	public async getConnectionsCount(
		sellerId: string,
		platform?: string | undefined,
		title?: string
	): Promise<AxiosResponse | any> {
		try {
			const { data } = await this.http.get<any>(
				`/Connections/${sellerId}/count?platform=${platform ?? ""}`
			);
			return {
				platform,
				// data: ['Amazon', 'Shopify', 'Square'].includes(platform as any) ? 5 : data,
				data,
				status: true,
				title,
			};
		} catch (error) {
			return { platform, data: null, status: false, title };
		}
	}
}

export const rutterService = new Service(rutterApi);
