import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";

import { Routes } from "./routes";
import { history } from "./utils";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import MixpanelProvider from "./providers/mixpanel";
import userManager from "./utils/user-manager";

import "./index.css";
import "notie/dist/notie.min.css";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<MixpanelProvider>
				<HelmetProvider>
					<OidcProvider store={store} userManager={userManager}>
						<Router history={history} children={Routes} />
					</OidcProvider>
				</HelmetProvider>
			</MixpanelProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
