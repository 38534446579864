const notie = require("notie");

interface ToastArgs {
	text: string;
	type?: "info" | "success" | "error" | "default";
	stay?: boolean;
	position?: "top" | "bottom";
	time?: 1 | 2 | 3 | 4 | 5;
}

export function toast(args: ToastArgs) {
	const {
		type = "info",
		text,
		stay = false,
		time = 3,
		position = "top",
	}: ToastArgs = args;
	return notie.alert({
		type,
		text,
		stay,
		time,
		position,
	});
}
