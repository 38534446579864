import { cloneElement, useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Person } from "../models/person";
import { lenkieApi } from "../utils";
import { organisationService } from "../services/organisation";
import { Organisation } from "../models/organisation";
import { FullLogo } from "../components/shared/logo-full";
import { OnboardingInfographicComponent } from "../components/pages/onboarding/infographic";
import { slugify } from "../utils/string";
import { LayoutPreloaderWide } from "../components/layout-preloader-wide";
import { openBankingService } from "../services/open-banking";
import { rutterService } from "../services/rutter";
import {
	ECOMMERCE_PLATFORMS,
	ONBOARDING_CAPTIONS,
	ONBOARDING_DOCUMENT_TITLE,
	PAYMENT_PLATFORMS,
} from "../constants";
import { OnboardingConnectionOptions } from "../../definition";
import { MixpanelConsumer } from "../providers/mixpanel";

function Layout({ children, ...props }: any) {
	const ONBOARDING_TEXT = (ONBOARDING_CAPTIONS as any)[props.match.path];
	const onboardingScopes = [
		"Contact details",
		"Business details",
		"Connections",
		"Your offers",
		"Sign agreement",
		"Get funded",
	].map((text: string, i: Number) => ({
		slug: slugify(text.toLowerCase(), "-"),
		text,
		active: false,
		state: undefined,
	}));

	const [person, setPerson] = useState({} as Person);
	const [fetched, setFetched] = useState(false);
	const [organisation, setOrganisation] = useState({} as Organisation);
	const [connections, setConnections] = useState({ fetched: false } as {
		[key: string]: any;
	});
	const location = props.match.path.split("/").pop();

	async function getOrganisation() {
		try {
			const { data: organisationData } =
				await organisationService.getMyOrganisation();
			setOrganisation(organisationData);
		} catch (error) {
			return null;
		}
	}

	const fetchData = useCallback(async () => {
		try {
			const [{ data: personData }, { data: organisationData }] =
				await Promise.all([
					await lenkieApi.get("/me"),
					await organisationService.getMyOrganisation(),
				]);

			if (personData) setPerson(personData);
			if (typeof organisationData?.id === "string") {
				const [
					{ data: openBanking } = {} as any,
					ecommerceCount = [] as any,
					paymentsCount = [] as any,
				] = await Promise.all([
					await openBankingService.getConnectionsCount(organisationData.id),
					await Promise.all(
						ECOMMERCE_PLATFORMS.map((ec: OnboardingConnectionOptions) =>
							rutterService.getConnectionsCount(
								organisationData.id,
								ec.title.replaceAll(" ", "")
							)
						)
					),
					await Promise.all(
						PAYMENT_PLATFORMS.map((ec: OnboardingConnectionOptions) =>
							rutterService.getConnectionsCount(
								organisationData.id,
								ec.title.replaceAll(" ", "")
							)
						)
					),
				]);
				setConnections((st: any) => ({
					openBanking,
					ecommerceCount,
					paymentsCount,
					fetched: true,
				}));
				setOrganisation(organisationData);
			}

			setFetched(true);
		} catch (error) {}
	}, []);

	function triggerRefreshData() {
		return fetchData();
	}

	useEffect(() => {
		if (!fetched) fetchData();
	}, [fetchData, fetched]);

	const [form, setForm] = useState({});
	const [stage, setStage] = useState("contact-details");

	const handleSubmit = (args: any) => {
		setForm(() =>
			Object.assign({ ...form }, { ...args, nextStage: undefined })
		);
		setStage(args.nextStage || "contact-details");
	};

	const goBack = (stage: string) => setStage(stage);

	function modifyConnectionsState(newState: any) {
		setConnections((c: any) => ({ ...c, ...newState }));
	}

	const validFetchedEcommerce = (connections.ecommerceCount ?? [])
		.filter((ec: any) => ec.data > 0 && ec.status)
		.map((ec: any) => ({
			...ec,
			file: ec.title.toLowerCase().replace(" ", "-"),
		}));

	const validFetchedPayments = (connections.paymentsCount ?? [])
		.filter((ec: any) => ec.data > 0 && ec.status)
		.map((ec: any) => ({
			...ec,
			file: ec.title.toLowerCase().replace(" ", "-"),
		}));

	const connectedEcommerce: string[] = validFetchedEcommerce.map(
		(ec: any) => ec.title
	);

	const connectedPayments: string[] = validFetchedPayments.map(
		(ec: any) => ec.title
	);

	const dataProps = {
		...props,
		callback: handleSubmit,
		goBack,
		form,
		person,
		organisation,
		connections,
		modifyConnectionsState,
		getOrganisation,
		triggerRefreshData,
		connectedEcommerce,
		connectedPayments,
	};

	function setOnboardingStages() {
		const progression = {
			"contact-details": [
				typeof person.work_phone_number === "string"
					? "completed"
					: "incomplete",
				location === "contact" ? "active" : "inactive",
			],
			"business-details": [
				typeof organisation.trading_name === "string"
					? "completed"
					: "incomplete",
				location === "business" ? "active" : "inactive",
			],
			connections: [
				(connections.openBanking ?? 0) > 0 ? "completed" : "incomplete",
				location === "connections" ? "active" : "inactive",
			],
		} as any;
		return onboardingScopes.map((sc) => {
			sc.active = sc.slug === stage;
			sc.state = (progression[sc.slug] || []).join("-");
			return sc;
		});
	}

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>
					Lenkie | Onboarding - {ONBOARDING_DOCUMENT_TITLE[location]}
				</title>
			</Helmet>
			{fetched ? (
				<MixpanelConsumer>
					{(mixpanel) => {
						mixpanel.identify(dataProps.person.id);
						return (
							<div
								id='onboarding-layout'
								className='grid grid-cols-12 w-full min-h-screen h-full relative'
							>
								<div className='md:col-span-6 lg:col-span-6 col-span-12 max-w-lg mx-auto w-full justify-center bg-gray-501 pb-48 md:px-8'>
									<div className='pt-8 px-8 md:px-0 mb-8'>
										<FullLogo />
									</div>

									<>
										{cloneElement(children, {
											...dataProps,
											mixpanel,
										})}
									</>
								</div>
								<div className='md:col-span-6 lg:col-span-6 hidden md:flex lg:flex bg-custom-dark items-start'>
									<div className='sticky top-0 self-start pt-144'>
										<OnboardingInfographicComponent
											scope={setOnboardingStages()}
											stage={stage}
											onboardingText={ONBOARDING_TEXT}
										/>
									</div>
								</div>
							</div>
						);
					}}
				</MixpanelConsumer>
			) : (
				<LayoutPreloaderWide />
			)}
		</>
	);
}

export const OnboardingLayout = withRouter(Layout);

export const onboardingLayoutWrapper = (Component: React.FunctionComponent) => {
	return (
		<OnboardingLayout>
			<Component />
		</OnboardingLayout>
	);
};
