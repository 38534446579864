import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FinancingOptionsState {
	loaded: boolean;
	options: any[];
}

const initialState: FinancingOptionsState = {
	loaded: false,
	options: [],
};

export const financingOptionsReducer = createSlice({
	name: "financingoptions",
	initialState,
	reducers: {
		financialOptionsDataLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setFinancialOptions: (state, action: PayloadAction<any>) => {
			state.loaded = true;
			state.options = action.payload;
		},
		updateFinancialOption: (state, action: PayloadAction<any>) => {
			const option = action.payload;
			const findIndex = state.options.findIndex(
				(op: any) => op.id === option.id
			);
			if (findIndex > -1) {
				state.options[findIndex] = option;
			} else state.options = [option, ...state.options];
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	financialOptionsDataLoaded,
	setFinancialOptions,
	updateFinancialOption,
} = financingOptionsReducer.actions;

export default financingOptionsReducer.reducer;
