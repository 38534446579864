import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import { GeneralPageTabComponent } from "../components/home-tab";
import { openBankingService } from "services/open-banking";
import { toast } from "utils/toast";
import { EcommercePaymentsComponent } from "components/connections/payments";
import { EcommerceConnectionsComponent } from "components/connections/ecommerce";
import { rutterService } from "services/rutter";
import { ECOMMERCE_PLATFORMS, PAYMENT_PLATFORMS } from "../constants";

export const Connections = (props: any) => {
	const [tab, setTab] = useState("growth-capital");

	function switchTab(tabname: string) {
		setTab(tabname);
	}

	const { mixpanel, triggerRefreshData } = props;
	const [ecommerceModal, toggleEcommerceModal] = useState(false);
	const [paymentsModal, togglePaymentsModal] = useState(false);
	const [connectBankAccount, setConnectBankAccount] = useState(0);
	const [connections] = useState(props.connections.data);
	const [bankAccounts, setBankAccounts] = useState<{
		[key: string]: any | undefined | null;
		fetched: boolean;
	}>({ fetched: false });
	const [fetchedConnections, setFetchedConnections] = useState<{
		ecommerce: any[];
		payments: any[];
		fetched: boolean;
	}>({ fetched: false, ecommerce: [], payments: [] });

	const fetchBankAccounts = useCallback(async (id: string) => {
		const { data } = await openBankingService.getConnection(id);
		setBankAccounts({
			fetched: true,
			data,
		});
	}, []);

	const fetchEcommerceConnections = useCallback(async (id: string) => {
		const ecommerce = await Promise.all(
			ECOMMERCE_PLATFORMS.map((ec: any) =>
				rutterService.getConnectionsCount(
					id,
					ec.title.replaceAll(" ", ""),
					ec.title
				)
			)
		);
		setFetchedConnections((state: any) => ({
			...state,
			fetched: true,
			ecommerce,
		}));
	}, []);

	const fetchPaymentConnections = useCallback(async (id: string) => {
		const payments = await Promise.all(
			PAYMENT_PLATFORMS.map((ec: any) =>
				rutterService.getConnectionsCount(
					id,
					ec.title.replaceAll(" ", ""),
					ec.title
				)
			)
		);
		setFetchedConnections((state: any) => ({
			...state,
			fetched: true,
			payments,
		}));
	}, []);

	useEffect(() => {
		const organisationId = props.organisation?.id;
		if (!organisationId) {
			triggerRefreshData();
		}

		if (organisationId) {
			if (!bankAccounts.fetched) {
				fetchBankAccounts(organisationId);
			}
			if (!fetchedConnections.fetched) {
				fetchEcommerceConnections(organisationId);
				fetchPaymentConnections(organisationId);
			}
		}

		async function handleOpenBankingAuthorization(id: string, code: string) {
			try {
				const response = await openBankingService.exchangeToken(id, code);
				if (response.status === 204) {
					setConnectBankAccount(1);
					await mixpanel.track("Connect Banking", {
						Platform: "TrueLayer",
						"Platform Type": "Open Banking",
						"Connection Status": "Connected",
					});
					toast({
						text: "Bank account connection successful.",
						type: "info",
					});
				}
			} catch (error) {
				toast({
					text: "Bank account connection failed.",
					type: "error",
				});
				await mixpanel.track("Connect Banking", {
					Platform: "TrueLayer",
					"Platform Type": "Open Banking",
					"Connection Status": "Failed",
				});
				setConnectBankAccount(2);
			} finally {
				window.history.replaceState(
					null,
					document.title,
					window.location.pathname
				);
			}
		}

		let code = new URLSearchParams(props.location?.search).get("code");
		let error = new URLSearchParams(props.location?.search).get("error");

		if (
			connectBankAccount === 0 &&
			props.organisation?.id &&
			typeof code === "string"
		) {
			handleOpenBankingAuthorization(props.organisation.id, code);
		}

		if ((error ?? "") === "access_denied") {
			window.history.replaceState(
				null,
				document.title,
				window.location.pathname
			);
			toast({
				text: "Bank account connection cancelled.",
				type: "error",
			});
		}
	}, [
		bankAccounts.fetched,
		connectBankAccount,
		fetchBankAccounts,
		fetchEcommerceConnections,
		mixpanel,
		props,
		triggerRefreshData,
		fetchedConnections,
		fetchPaymentConnections,
	]);

	async function openBanking() {
		window.open(process.env.REACT_APP_OPEN_BANKING_URL, "_blank");
		await mixpanel.track("Connect Banking", {
			Platform: "TrueLayer",
			"Platform Type": "Open Banking",
			"Connection Status": "Opened",
		});
	}

	const validFetchedEcommerce = fetchedConnections.ecommerce
		.filter((ec: any) => ec.data > 0 && ec.status)
		.map((ec: any) => ({
			...ec,
			file: ec.title.toLowerCase().replace(" ", "-"),
		}));

	const validFetchedPayments = fetchedConnections.payments
		.filter((p: any) => p.data > 0 && p.status)
		.map((p: any) => ({
			...p,
			file: p.title.toLowerCase().replace(" ", "-"),
		}));

	return (
		<>
			<Helmet>
				<title>Connections | Lenkie</title>
			</Helmet>
			<GeneralPageTabComponent
				active={tab}
				name={"Connections"}
				switchTab={switchTab}
			/>
			<div className='md:p-14 lg:p-14 p-4 h-auto max-w-6xl'>
				<div className='pb-16 sm:p-0 md:p-0 rounded-lg'>
					<EcommerceConnectionsComponent
						showModal={ecommerceModal}
						toggleModal={toggleEcommerceModal}
						{...props}
						connectedEcommerce={validFetchedEcommerce.map((ec) => ec.title)}
						modal={{
							cancelButtonText: "Close",
							hideOnClick: true,
						}}
						ecommerceCallback={() =>
							fetchEcommerceConnections(props.organisation?.id)
						}
					/>
					<EcommercePaymentsComponent
						showModal={paymentsModal}
						toggleModal={togglePaymentsModal}
						{...props}
						connectedPayments={validFetchedPayments.map((ec) => ec.title)}
						modal={{
							cancelButtonText: "Close",
							hideOnClick: true,
						}}
						stripeRedirect={window.location.pathname}
					/>

					<div className='border-b border-dashed border-custom-gray pb-12 max-w-xl'>
						<legend className='font-firma-semibold md:font-firma-medium lg:font-firma-medium text-custom-dark text-xl md:text-2xl lg:text-2xl'>
							Connect bank account
						</legend>
						<div className='mt-7'>
							<p className='font-firma text-custom-dark text-md'>
								What is your bank account data used for?
							</p>
							<p className='font-firma-light text-custom-dark text-md mt-4'>
								Connecting your bank accounts enables us to determine the
								appropriate cash advance amount for your business.
							</p>
						</div>
						{props.connections.fetched && (
							<div className='mt-8'>
								{(connections.connectionsCount.openBanking ?? 0) > 0 ||
								connectBankAccount === 1 ? (
									<div className='flex text-custom-green justify-between items-center'>
										<h3 className='flex items-center gap-x-2 justify-between text-sm'>
											Connected
											<span className='text-custom-green'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													className='h-5 w-5'
													fill='none'
													viewBox='0 0 24 24'
													stroke='currentColor'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='1'
														d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
													/>
												</svg>
											</span>
										</h3>
										<button
											onClick={() => openBanking()}
											className={
												"font-firma-medium tracking-wide text-sm text-custom-dark hover:underline"
											}
										>
											Add another account
										</button>
									</div>
								) : (
									<button
										onClick={() => openBanking()}
										className={
											"font-firma-light tracking-wide px-8 py-4 text-sm rounded-lg border border-custom-dark bg-custom-dark text-white"
										}
									>
										Connect account
									</button>
								)}
							</div>
						)}
					</div>
					<div className='border-b border-dashed border-custom-gray py-12 max-w-xl'>
						<div className='grid items-center justify-between'>
							<legend className='font-firma-semibold md:font-firma-medium lg:font-firma-medium text-custom-dark text-xl md:text-2xl lg:text-2xl'>
								Connect e-commerce platform
							</legend>
						</div>
						<div className='mt-7'>
							<p className='font-firma text-custom-dark text-md'>
								What is your e-commerce data used for?
							</p>
							<p className='font-firma-light text-custom-dark text-md mt-4'>
								We integrate with your e-commerce accounts to analyse your
								historical sales. Add all your e-commerce accounts so we can
								provide you with the most competitive rate.
							</p>
						</div>
						<div className='flex -space-x-2 overflow-hidden mt-2'>
							{validFetchedEcommerce.map((ec: any, i: number) => (
								<span key={i}>
									<img
										key={i}
										className='inline-block cursor-pointer h-10 w-10 rounded-full ring-2 ring-white bg-white border border-gray-50 p-0.5'
										src={`/icons/ic-${ec.file}.svg`}
										alt={ec.title}
										data-tip
										data-for={ec.file}
									/>

									<ReactTooltip id={ec.file} className='bg-custom-dark'>
										<span className='text-xs font-firma-light'>{ec.title}</span>
									</ReactTooltip>
								</span>
							))}
						</div>
						{props.connections.fetched && (
							<div className='mt-8'>
								{validFetchedEcommerce.length > 0 ? (
									<div className='flex text-custom-green justify-between items-center'>
										<h3 className='flex items-center gap-x-2 justify-between text-sm'>
											Connected
											<span className='text-custom-green'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													className='h-5 w-5'
													fill='none'
													viewBox='0 0 24 24'
													stroke='currentColor'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='1'
														d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
													/>
												</svg>
											</span>
										</h3>
										<button
											onClick={() => toggleEcommerceModal(true)}
											className={
												"font-firma-medium tracking-wide text-sm text-custom-dark hover:underline"
											}
										>
											Add another account
										</button>
									</div>
								) : (
									<button
										onClick={() => toggleEcommerceModal(true)}
										className={
											"font-firma-light tracking-wide px-8 py-4 text-sm rounded-lg border border-custom-dark bg-custom-dark text-white"
										}
									>
										Connect account
									</button>
								)}
							</div>
						)}
					</div>
					<div className='pt-12 max-w-xl'>
						<div className='flex items-center justify-between'>
							<legend className='font-firma-semibold md:font-firma-medium lg:font-firma-medium text-custom-dark text-xl md:text-2xl lg:text-2xl'>
								Connect payment software
							</legend>
						</div>
						<div className='mt-7'>
							<p className='font-firma text-custom-dark text-md'>
								What is your payment software used for?
							</p>
							<p className='font-firma-light text-custom-dark text-md mt-4'>
								We integrate with your payment software to analyse your
								historical sales. Connect your payment accounts so we can
								provide you with the most competitive rate.
							</p>
						</div>

						<div className='flex -space-x-2 overflow-hidden mt-2'>
							{validFetchedPayments.reverse().map((p: any, i: number) => (
								<span key={i}>
									<img
										key={i}
										className='inline-block cursor-pointer h-12 w-12 rounded-full ring-2 ring-white bg-white border border-gray-50 p-0.5'
										src={`/icons/ic-${p.file}.svg`}
										alt={p.title}
										data-tip
										data-for={p.file}
									/>

									<ReactTooltip id={p.file} className='bg-custom-dark'>
										<span className='text-xs font-firma-light'>{p.title}</span>
									</ReactTooltip>
								</span>
							))}
						</div>
						{props.connections.fetched && (
							<div className='mt-8'>
								{validFetchedPayments.length > 0 ? (
									<div className='flex text-custom-green justify-between items-center'>
										<h3 className='flex items-center gap-x-2 justify-between text-sm'>
											Connected
											<span className='text-custom-green'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													className='h-5 w-5'
													fill='none'
													viewBox='0 0 24 24'
													stroke='currentColor'
												>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth='1'
														d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
													/>
												</svg>
											</span>
										</h3>
										<button
											onClick={() => togglePaymentsModal(true)}
											className={
												"font-firma-medium tracking-wide text-sm text-custom-dark hover:underline"
											}
										>
											Add another account
										</button>
									</div>
								) : (
									<button
										onClick={() => togglePaymentsModal(true)}
										className={
											"font-firma-light tracking-wide px-8 py-4 text-sm rounded-lg border border-custom-dark bg-custom-dark text-white"
										}
									>
										Connect account
									</button>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
