import { Link } from "react-router-dom";

export function DashboardLayoutMenuBar(props: any) {
	function getInitials(name: string): string {
		const exploded = name.split(" ");
		if (exploded.length > 1) {
			return exploded[0][0] + exploded[1][0];
		}
		return exploded[0][0];
	}

	return (
		<nav
			id='menu-bar'
			className={
				props.customClass ||
				"px-6 md:px-12 flex items-center justify-end border-b border-transparent w-full"
			}
		>
			<Link
				to='settings#profile'
				className='hidden flex-row items-center justify-center gap-x-4 cursor-pointer'
			>
				<p data-initials={(getInitials(props.person?.given_name ?? '') ?? '').toUpperCase()}></p>
			</Link>
		</nav>
	);
}
