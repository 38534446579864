import { FullLogo } from "../../components/shared/logo-full";

export function StripeConnectionsCallback() {
	const code = new URLSearchParams(window.location.href).get("code");
	const error = new URLSearchParams(window.location.href).get("error");

	const redirectCallbackPath =
		localStorage.getItem("stripeRedirect") ?? "/onboarding/connections";
	return (
		<div className='flex flex-col gap-y-4 items-center justify-center min-h-screen text-gray-600 font-firma-medium'>
			<FullLogo />
			{error && error === "access_denied" && (
				<div className='text-center'>
					<h3 className='font-firma-semibold'>Connection unsuccessful</h3>
					<h3 className='font-firma-light'>
						Failed to connect your stripe account.
					</h3>
				</div>
			)}
			{code && !error && (
				<div className='text-center grid gap-y-2'>
					<h3 className='font-firma-semibold'>Connection successful</h3>
					<h3 className='font-firma-light'>
						You have successfully connected your stripe account.
					</h3>
				</div>
			)}
			<button
				onClick={() => window.location.replace(redirectCallbackPath)}
				className='mt-8 bg-custom-dark w-fit py-2 px-8 font-firma-light rounded-lg text-white mx-auto'
			>
				Continue
			</button>
		</div>
	);
}
