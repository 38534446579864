import React from "react";
import AuthService from "../services/auth";

export const AuthContext = React.createContext(new AuthService());

export const AuthConsumer = AuthContext.Consumer;

export default function AuthProvider(props: any) {
	
	return (
		<AuthContext.Provider value={new AuthService()}>
			{props.children}
		</AuthContext.Provider>
	);
}
