import { FullLogo } from "./shared/logo-full";

interface Props {
	className?: string;
	text?: string;
}

export function LayoutPreloaderWide(props: Props) {
	return (
		<div className={'flex items-center min-h-screen justify-center ' + props.className}>
			<div className='grid justify-center items-center h-fit gap-y-4'>
				<FullLogo className="mx-auto" />
				{!props.text && <svg
				style={{
						margin: "auto",
						background: "0 0",
					}}
					xmlns='http://www.w3.org/2000/svg'
					width='40'
					height='40'
					viewBox='0 0 100 100'
					preserveAspectRatio='xMidYMid'
					display='block'
				>
					<circle
						cx='50'
						cy='50'
						r='33'
						strokeWidth='4'
						stroke='#828282'
						strokeDasharray='51.83627878423159 51.83627878423159'
						fill='none'
						strokeLinecap='round'
					>
						<animateTransform
							attributeName='transform'
							type='rotate'
							repeatCount='indefinite'
							dur='0.7633587786259541s'
							keyTimes='0;1'
							values='0 50 50;360 50 50'
						/>
					</circle>
				</svg>}
				<h3 className="text-custom-gray font-firma-light text-sm">{props.text}</h3>
			</div>
		</div>
	);
}
