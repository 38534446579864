import { PartnerViewBoundary } from "components/boundaries/partner";
import { lazy, Suspense, useCallback } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import userManager from "utils/user-manager";

function displayView(name: string, props: any = {}) {
	let View = lazy(() => import("../views/partner/" + name));
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<View {...props} />
		</Suspense>
	);
}

function Layout(props: any) {
	const redirectToSignIn = useCallback(async (options = {}) => {
		return await userManager.signinRedirect(options);
	}, []);
	const path: string = props.match.url?.replaceAll("/", "");
	const params = new URL(encodeURI(window.location.href));
	const platformId = params.searchParams.get("platformId");
	const sellerId = params.searchParams.get("sellerId");

	function signIn() {
		return redirectToSignIn({
			extraQueryParams: {
				platformId,
				sellerId,
			},
		});
	}
	return (
		<PartnerViewBoundary>
			<Helmet>
				<title>{path.toUpperCase()} x Lenkie | Cash Advance Offers</title>
			</Helmet>
			{displayView(path, {
				signIn,
			})}
		</PartnerViewBoundary>
	);
}

export const PartnerLayout = withRouter(Layout);
