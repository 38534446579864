export default function DefaultComingSoonComponent(props: any) {
	return (
		<div className='h-auto flex items-center w-full mt-40'>
			<div className='grid top-40 items-center justify-center w-full text-center gap-y-8'>
				<span>
					<svg
						className='mx-auto'
						width='100'
						height='100'
						viewBox='0 0 147 147'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M73.5 147C114.093 147 147 114.093 147 73.5C147 32.9071 114.093 0 73.5 0C32.9071 0 0 32.9071 0 73.5C0 114.093 32.9071 147 73.5 147Z'
							fill='#1A2D5B'
						/>
						<path
							opacity='0.7'
							d='M103.175 104.478C103.175 127.102 89.8631 117.889 73.4692 117.889C57.0959 117.889 43.8257 127.097 43.8257 104.478C43.8257 94.2117 64.9282 78.2381 71.5317 73.5088C64.9282 68.7807 43.8257 52.7899 43.8257 42.524C43.8257 19.9216 57.0959 29.1125 73.4692 29.1125C89.8631 29.1125 103.175 19.925 103.175 42.524C103.175 52.7899 82.0308 68.7807 75.4066 73.5088C82.0308 78.2381 103.175 94.2117 103.175 104.478Z'
							fill='#EEEFEE'
						/>
						<path
							d='M101.575 106.766C101.575 124.919 91.7131 115.915 73.4598 115.915C55.2674 115.915 45.4253 124.919 45.4253 106.766C45.4253 100.787 73.8101 82.8184 73.8101 82.8184C73.8101 82.8184 101.575 100.787 101.575 106.766Z'
							fill='#F5A623'
						/>
						<path
							d='M93.1979 56.53L92.7661 56.9618C87.1551 62.4386 81.098 67.439 74.6576 71.911L73.833 72.4853L73.0291 71.911C65.2013 66.3802 56.5685 59.2369 51.1536 52.8412C50.8206 52.4496 50.5059 52.0764 50.2119 51.6836C53.4966 50.4433 56.9992 49.8838 60.5068 50.039C64.0144 50.1942 67.4539 51.0608 70.6162 52.5863L70.6897 52.6207C74.4186 54.274 78.4031 55.2765 82.4704 55.5848L93.1979 56.53Z'
							fill='#F5A623'
						/>
						<path
							d='M106.401 26.4141H40.5997C39.5164 26.4141 38.6382 27.2923 38.6382 28.3756V33.5367C38.6382 34.62 39.5164 35.4982 40.5997 35.4982H106.401C107.484 35.4982 108.362 34.62 108.362 33.5367V28.3756C108.362 27.2923 107.484 26.4141 106.401 26.4141Z'
							fill='#575B6D'
						/>
						<path
							d='M106.401 111.502H40.5997C39.5164 111.502 38.6382 112.38 38.6382 113.463V118.625C38.6382 119.708 39.5164 120.586 40.5997 120.586H106.401C107.484 120.586 108.362 119.708 108.362 118.625V113.463C108.362 112.38 107.484 111.502 106.401 111.502Z'
							fill='#575B6D'
						/>
						<path
							d='M73.1509 70.252H74.4681V87.0513H73.1509V70.252Z'
							fill='#F5A623'
						/>
						<path
							opacity='0.35'
							d='M90.5304 46.6413C88.7411 44.5649 88.4965 41.8673 89.9711 40.5959C91.4457 39.3246 94.0791 39.9655 95.8684 42.043C97.6576 44.1205 97.9022 46.817 96.4265 48.0884C94.9508 49.3597 92.3116 48.7097 90.5304 46.6413Z'
							fill='white'
						/>
					</svg>
				</span>
				{props.children ? (
					props.children
				) : (
					<>
						<h3 className='text-xl font-firma-semibold text-custom-dark'>
							This feature is under construction.
						</h3>
						<h3 className='text font-firma'>
							We’re almost there! You’ll be the first to be notified when it’s
							ready.
						</h3>
						{/* <Link
							to='/growth-capital'
							className='border border-custom-dark px-8 py-4 text-sm rounded-lg w-fit mt-4 font-firma text-custom-dark mx-auto'
						>
							Explore Growth Capital
						</Link> */}
					</>
				)}
			</div>
		</div>
	);
}
