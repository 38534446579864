import { AxiosInstance, AxiosResponse } from "axios";
import { lenkieApi } from "../utils";

export class Service {
	constructor(private http: AxiosInstance) {}

	public async getCountries(): Promise<AxiosResponse> {
		return await this.http.get("/Countries");
	}
}

export const countryService = new Service(lenkieApi);
