import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import {
	connectionsReducer,
	dashboardReducer,
	organisationReducer,
	personReducer,
	financingOptionsReducer,
	financingAssessmentsReducer,
} from "../reducers";
import { history as browserHistory } from "../utils";
import { routerMiddleware, routerReducer } from "react-router-redux";
import userManager from "../utils/user-manager";

export const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					"redux-oidc/USER_FOUND",
					"redux-oidc/SILENT_RENEW_ERROR",
				],
				ignoredPaths: ["oidc.user"],
			},
		}).concat(routerMiddleware(browserHistory)),
	reducer: {
		routing: routerReducer,
		oidc: oidcReducer,
		person: personReducer,
		dashboard: dashboardReducer,
		organisation: organisationReducer,
		connections: connectionsReducer,
		financingOptions: financingOptionsReducer,
		financingAssessments: financingAssessmentsReducer,
	},
});

loadUser(store, userManager);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
