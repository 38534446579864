import { useForm } from "react-hook-form";
import { organisationService } from "../../../services/organisation";
import { Guid } from "../../../utils/guid";
import { toast } from "../../../utils/toast";

export function OnboardingBusinessManualComponent(props: any) {
	const { organisation, countries } = props;
	const {
		register,
		handleSubmit,
		clearErrors,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: { organisation } as any,
	});

	const onSubmit = async ({ organisation }: any) => {
		try {
			clearErrors();
			if (!isDirty) {
				return props.history.push("/onboarding/connections");
			}

			organisation = {
				id: Guid.newGuid(),
				...organisation,
			};

			const { status } = await organisationService.patchOrganisation(
				organisation.id,
				organisation
			);

			if (status === 204) {
				toast({ text: "Your business details have ben updated successfully" });
				return props.history.push("/onboarding/connections");
			}
		} catch (error: Error | any) {
			toast({
				text: error.message ?? "Business details details could not be updated",
				type: "error",
			});
		}
	};
	return (
		<div className="mt-8">
			<button onClick={() => props.setMode('advanced')} className='text-md font-firma-semibold text-custom-dark'>
				Add your business details automatically&nbsp;&nbsp;&#8594;	
			</button>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='w-full grid gap-y-6 mt-8 contact'>
					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>
							Company name
						</label>
						<input
							{...register("organisation.registered_name", { required: true })}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm px-4 py-3'
							placeholder='Enter company name'
						/>
						{errors.organisation?.registered_name && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>
					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>
							Company registeration number
						</label>
						<input
							{...register("organisation.registration_number", {
								required: true,
							})}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm px-4 py-3'
							placeholder='Enter your company registeration number'
						/>
						{errors.organisation?.registration_number && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>

					<div className='mt-4 mb-2 border-b border-gray-200'></div>

					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>
							Address Line
						</label>
						<input
							{...register("organisation.physical_address.street_address", {
								required: true,
							})}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm px-4 py-3'
							placeholder='Enter company address'
						/>
						{errors.organisation?.physical_address?.street_address && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>

					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>City</label>
						<input
							{...register("organisation.physical_address.suburb", {
								required: true,
							})}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm px-4 py-3'
							placeholder='Enter city'
						/>
						{errors.organisation?.physical_address?.suburb && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>

					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>
							County
						</label>
						<input
							{...register("organisation.physical_address.town", {
								required: true,
							})}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm px-4 py-3'
							placeholder='Enter county'
						/>
						{errors.organisation?.physical_address?.town && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>

					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>
							Postal Code
						</label>
						<input
							{...register("organisation.physical_address.postal_code", {
								required: true,
							})}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm px-4 py-3'
							placeholder='Enter postal code'
						/>
						{errors.organisation?.physical_address?.postal_code && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>

					<div className='input grid'>
						<label className='text-custom-dark font-firma text-sm'>
							Country
						</label>
						<select
							{...register("organisation.physical_address.country.href", {
								required: true,
							})}
							className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3 text-sm'
							placeholder='Enter postal code'
							defaultValue={
								organisation.physical_address?.country?.name || "GB"
							}
						>
							{countries.map((country: any) => (
								<option key={country.code} value={country.href}>
									{country.emoji}&nbsp;&nbsp;{country.name}
								</option>
							))}
						</select>
						{errors.organisation?.physical_address?.country?.href && (
							<span className='text-red-600 text-sm font-firma-medium mt-1'>
								This field is required/invalid
							</span>
						)}
					</div>

					<div className='grid text-center gap-y-4'>
						<button type="submit" className='text-center bg-custom-dark font-firma-light rounded-lg text-white p-4 sm:px-8 text-sm sm:mt-8 mt-4'>
							Continue
						</button>
						<a
              href="#!"
							className='underline text-sm w-fit mx-auto'
							onClick={() => props.history.push("/onboarding/contact")}
						>
							back
						</a>
					</div>
				</div>
			</form>
		</div>
	);
}
