export function LogoMobile({ className }: any) {
	return (
		<svg
			className={className}
			viewBox='0 0 10 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.66416 9.76351C8.30589 9.04236 9.45292 7.39886 9.45292 5.48698C9.45292 2.90973 7.36865 0.820312 4.79775 0.820312C2.22685 0.820312 0.142578 2.90973 0.142578 5.48698C0.142578 7.40156 1.29251 9.04671 2.93796 9.76621L1.42575 15.4154L0.203611 19.8755C-0.0175617 20.6827 0.389816 21.5611 0.985471 21.5611H8.61003C9.20382 21.5611 9.61119 20.6881 9.39354 19.8815L6.66416 9.76351Z'
				fill='#F5A623'
			/>
		</svg>
	);
}
