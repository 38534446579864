import mixpanel, { Dict } from "mixpanel-browser";

// let env = process.env.NODE_ENV !== "production";
let env = true;

export const Mixpanel = new (class {
	protected mixpanel: any;

	constructor(token: string) {
		if (typeof token !== "string" || token.length < 1) {
			throw new Error("Invalid mixpanel token");
		}

		mixpanel.init(token, {
			api_host: process.env.REACT_APP_MIXPANEL_API,
			loaded: (mixpanel) => {
				this.mixpanel = mixpanel;
			},
		});
	}

	identify = (id: string) => {
		if (env) this.mixpanel.identify(id);
	};

	alias = (id: string) => {
		if (!env) this.mixpanel.alias(id);
	};

	track = (name: string, props?: Dict) => {
		if (env) this.mixpanel.track(name, props);
	};

	setPeople = (props: Dict) => {
		if (env) this.mixpanel.people.set(props);
	};

	setOnce = (props: Dict) => {
		if (env) this.mixpanel.people.set_once(props);
	};

})(process.env.REACT_APP_MIXPANEL_TOKEN as string);
