import { useMemo, useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { useAppSelector, RootState } from "../store";
import { FullLogoInverted } from "./shared/logo-full";
import { LogoMobile } from "./shared/logo-mobile";
import { ModalComponent } from "./shared/modal";

export function DashboardLayoutSideBar(props: any) {
	const [help, setHelp] = useState(false);
	const {
		data: { connectionsCount },
	} = useAppSelector((state: RootState) => state.connections);
	const { assessments } = useAppSelector(
		(state: RootState) => state.financingAssessments
	);
	const openBankingCount = connectionsCount.openBanking ?? 0;
	const ecommerceCount = (connectionsCount.ecommerce ?? []).filter(
		(e: any) => e.data > 0
	);
	const paymentsCount = (connectionsCount.payments ?? []).filter(
		(e: any) => e.data > 0
	);

	const isBankingConnected = openBankingCount > 0;
	const isEcommerceConnected =
		ecommerceCount?.length > 0 || paymentsCount?.length > 0;

	function isfinancialAssessmentsApproved() {
		return assessments?.some((a) => a.is_approved);
	}

	const cashAdvances = useMemo(() => {
		const cashArr = [] as any;

		(assessments ?? [])
			.filter((a) => a.is_approved)
			.forEach((approved) => {
				cashArr.push(...(approved.available_cash_advance_options ?? []));
			});
		return cashArr;
	}, [assessments]);

	
	function checkConnectionsState(): number|null {
		let stage: number | null = null;
		const financialAssessmentsApproved = isfinancialAssessmentsApproved();
		const eitherConnectionsDone = isBankingConnected || isEcommerceConnected;

		if (!eitherConnectionsDone) return 0;
		if (!financialAssessmentsApproved) stage = 1;

		if (
			financialAssessmentsApproved &&
			cashAdvances.some(
				(ca: any) => ca.status.id.toLowerCase() === "selected"
			) &&
			!cashAdvances.some(
				(ca: any) => ca.status.id.toLowerCase() === "disbursed"
			)
		)
			stage = 2;

		return stage;
	}

	const connectionState = checkConnectionsState();

	return (
		<>
			<ModalComponent
				show={help}
				hideModal={() => setHelp(false)}
				className='max-w-xs h-auto top-40 absolute mx-auto w-11/12'
				wrapperStyle={{ zIndex: "999999" }}
			>
				<div className='rounded-lg text-white w-full px-8 py-12 bg-custom-dark text-center'>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-12 w-12 text-custom-orange mx-auto'
							viewBox='0 0 20 20'
							fill='currentColor'
						>
							<path
								fillRule='evenodd'
								d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'
								clipRule='evenodd'
							/>
						</svg>
					</div>
					<p className='font-firma-semibold mt-8'>Have questions to ask?</p>
					<p>We’re here to help</p>

					<div className='mt-8'>
						<a
							href='tel:+447939498942'
							className='underline font-firma-light mx-auto w-fit'
						>
							+44 7939 498942
						</a>
						<br />
						<a
							href='mailto:hello@lenkie.com'
							className='underline font-firma-light mx-auto w-fit'
						>
							hello@lenkie.com
						</a>
					</div>
				</div>
			</ModalComponent>
			<aside
				id='sidebar'
				className={props.customClass || "bg-custom-dark md:w-72 min-w-min"}
			>
				<div className='sticky top-0'>
					<div className='logo-wrapper flex items-center border-b border-gray-700 sm:mx-auto md:pl-8 lg:pl-8'>
						<FullLogoInverted className='hidden md:block lg:block font-firma' />
						<LogoMobile className='md:hidden lg:hidden mx-auto h-6' />
					</div>
					<ul id='sidebar' className='mt-16'>
						<li>
							<Link
								className='
              flex
              sm:pl-8 pl-4 md:pl-8 lg:pl-8
              hover:bg-custom-dark-lighter-2
              sm:py-6 py-4 md:py-6 lg:py-6
              hover:border-l-4
              text-custom-dark-lighter
              hover:text-white
              font-firma-thin
            '
								to='/'
								exact
							>
								<span className='mr-4 sm:mr-9'>
									<svg
										width='18'
										height='18'
										viewBox='0 0 16 16'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M10.8572 1.33337H13.035C13.936 1.33337 14.6669 2.07064 14.6669 2.98016V5.17611C14.6669 6.08494 13.936 6.8229 13.035 6.8229H10.8572C9.95553 6.8229 9.22461 6.08494 9.22461 5.17611V2.98016C9.22461 2.07064 9.95553 1.33337 10.8572 1.33337Z'
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M2.96611 1.33337H5.14316C6.04485 1.33337 6.77577 2.07064 6.77577 2.98016V5.17611C6.77577 6.08494 6.04485 6.8229 5.14316 6.8229H2.96611C2.06442 6.8229 1.3335 6.08494 1.3335 5.17611V2.98016C1.3335 2.07064 2.06442 1.33337 2.96611 1.33337Z'
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M2.96611 9.17725H5.14316C6.04485 9.17725 6.77577 9.91451 6.77577 10.8247V13.02C6.77577 13.9295 6.04485 14.6668 5.14316 14.6668H2.96611C2.06442 14.6668 1.3335 13.9295 1.3335 13.02V10.8247C1.3335 9.91451 2.06442 9.17725 2.96611 9.17725Z'
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M10.8572 9.17725H13.035C13.936 9.17725 14.6669 9.91451 14.6669 10.8247V13.02C14.6669 13.9295 13.936 14.6668 13.035 14.6668H10.8572C9.95553 14.6668 9.22461 13.9295 9.22461 13.02V10.8247C9.22461 9.91451 9.95553 9.17725 10.8572 9.17725Z'
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</span>
								<span className='hidden md:block lg:block font-firma'>
									Home
								</span>
							</Link>
						</li>
						<li>
							<Link
								className='
              flex
              sm:pl-8 pl-4 md:pl-8 lg:pl-8
              hover:bg-custom-dark-lighter-2
              sm:py-6 py-4 md:py-6 lg:py-6
              hover:border-l-4
              text-custom-dark-lighter
              hover:text-white
              font-firma-thin
            '
								to='/connections'
							>
								<span className='mr-4 sm:mr-9 relative'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='h-6 w-6'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4'
										/>
									</svg>
									{connectionState === 0 && (
										<span className='flex absolute top-0 right-0 -mr-1 h-2 w-2'>
											<span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-custom-yellow opacity-75'></span>
											<span className='relative inline-flex rounded-full h-2 w-2 bg-custom-orange'></span>
										</span>
									)}
								</span>
								<span className='hidden relative md:block lg:block font-firma'>
									Connections
								</span>
							</Link>
						</li>
						<li>
							<Link
								className='
              flex
              sm:pl-8 pl-4 md:pl-8 lg:pl-8
              hover:bg-custom-dark-lighter-2
              sm:py-6 py-4 md:py-6 lg:py-6
              hover:border-l-4
              text-custom-dark-lighter
              hover:text-white
              font-firma-thin
            '
								to='/wallet'
							>
								<span className='mr-4 sm:mr-9'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										height='18'
										width='18'
										fill='none'
										viewBox='0 0 24 24'
										stroke='currentColor'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z'
										/>
									</svg>
								</span>
								<span className='hidden md:block lg:block font-firma'>
									Wallet
								</span>
							</Link>
						</li>
						{/* <li>
							<Link
								className='
              flex
              sm:pl-8 pl-4 md:pl-8 lg:pl-8
              hover:bg-custom-dark-lighter-2
              sm:py-6 py-4 md:py-6 lg:py-6
              hover:border-l-4
              text-custom-dark-lighter
              hover:text-white
              font-firma-thin
            '
								to='/settings'
							>
								<span className='mr-4 sm:mr-9'>
									<svg
										width='18'
										height='18'
										viewBox='0 0 14 14'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M12.8709 4.08237L12.456 3.36231C12.1049 2.75303 11.327 2.54284 10.7169 2.89244V2.89244C10.4264 3.06352 10.0799 3.11206 9.75364 3.02735C9.42739 2.94264 9.14824 2.73164 8.97773 2.44087C8.86804 2.25606 8.8091 2.04556 8.80687 1.83065V1.83065C8.81677 1.48611 8.68678 1.15223 8.44652 0.905072C8.20626 0.657917 7.8762 0.518535 7.53151 0.518677H6.6955C6.35782 0.518673 6.03405 0.653236 5.79584 0.892589C5.55763 1.13194 5.42462 1.45635 5.42624 1.79404V1.79404C5.41624 2.49124 4.84816 3.05117 4.15088 3.05109C3.93598 3.04886 3.72548 2.98992 3.54066 2.88023V2.88023C2.93055 2.53064 2.15258 2.74082 1.80153 3.3501L1.35607 4.08237C1.00544 4.69089 1.21277 5.46836 1.81984 5.8215V5.8215C2.21444 6.04932 2.45752 6.47035 2.45752 6.926C2.45752 7.38165 2.21444 7.80268 1.81984 8.0305V8.0305C1.21354 8.38125 1.00599 9.15684 1.35607 9.76353V9.76353L1.77712 10.4897C1.9416 10.7865 2.21757 11.0055 2.54397 11.0983C2.87036 11.191 3.22026 11.1499 3.51625 10.984V10.984C3.80722 10.8142 4.15396 10.7677 4.4794 10.8548C4.80483 10.9418 5.08199 11.1553 5.24928 11.4477C5.35897 11.6326 5.41791 11.8431 5.42014 12.058V12.058C5.42014 12.7623 5.99114 13.3333 6.6955 13.3333H7.53151C8.23349 13.3333 8.80351 12.766 8.80687 12.0641V12.0641C8.80524 11.7253 8.93908 11.4 9.17861 11.1604C9.41815 10.9209 9.74349 10.7871 10.0822 10.7887C10.2966 10.7944 10.5063 10.8531 10.6925 10.9596V10.9596C11.301 11.3102 12.0784 11.1029 12.4316 10.4958V10.4958L12.8709 9.76353C13.041 9.47162 13.0877 9.12397 13.0006 8.79754C12.9136 8.47112 12.7 8.19286 12.4072 8.0244V8.0244C12.1143 7.85593 11.9008 7.57768 11.8137 7.25125C11.7267 6.92483 11.7733 6.57717 11.9434 6.28527C12.054 6.09218 12.2141 5.93209 12.4072 5.8215V5.8215C13.0106 5.46855 13.2174 4.69562 12.8709 4.08847V4.08847V4.08237Z'
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<ellipse
											cx='7.11681'
											cy='6.92589'
											rx='1.75744'
											ry='1.75744'
											stroke='currentColor'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</span>
								<span className='hidden md:block lg:block font-firma'>
									Settings
								</span>
							</Link>
						</li> */}
					</ul>
				</div>
				<div className='absolute bottom-0 w-full'>
					<button
						className='flex
              sm:pl-8 pl-4 md:pl-8 lg:pl-8
              hover:bg-custom-dark-lighter-2
              sm:py-6 py-4 md:py-6 lg:py-6
              hover:border-l-4
              text-custom-dark-lighter
              hover:text-white
              font-firma-thin w-full
            '
						onClick={() => setHelp(true)}
					>
						<span className='mr-4 sm:mr-9'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								height='18'
								width='18'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
						</span>
						<span className='hidden md:block lg:block font-firma'>Help</span>
					</button>
					<button
						className='flex
              sm:pl-8 pl-4 md:pl-8 lg:pl-8
              hover:bg-custom-dark-lighter-2
              sm:py-6 py-4 md:py-6 lg:py-6
              hover:border-l-4
              text-custom-dark-lighter
              hover:text-white
              font-firma-thin w-full
            '
						onClick={props.logout}
					>
						<span className='mr-4 sm:mr-9'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								height='18'
								width='18'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
								/>
							</svg>
						</span>
						<span className='hidden md:block lg:block font-firma'>Logout</span>
					</button>
				</div>
			</aside>
		</>
	);
}
