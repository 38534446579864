import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DashboardState {
	loaded: boolean;
}

const initialState: DashboardState = {
	loaded: false,
};

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		dashboardDataLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { dashboardDataLoaded } = dashboardSlice.actions;

export default dashboardSlice.reducer;
