import { useMemo, useState } from "react";
import numeral from "numeral";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { flattenDeep } from "lodash";
import { ContentDisplayTableNoneComponent } from "../../shared/content-table-display-none";
import { TableHomeHistoryGrowthCapitalComponent } from "../../shared/table-home-history-growth-capital";
import { AlertConnectionsComponent } from "../../alerts/connections";
import { CashAdvanceFinancingOptionsModal } from "components/modals/financing-options";

interface HomeGrowthCapitalProps {
	summary: {
		totalLoanVolume: number;
		total: number;
		totalNumberOfLoanApplication: number;
	};
	status?: boolean;
	assessments?: any[];
	connections?: any;
	organisation?: any;
	instantSettlementOptionActive: any;
}

export function HomeGrowthCapitalComponent(props: HomeGrowthCapitalProps) {
	const { assessments, connections: { data: connections }, instantSettlementOptionActive = {} } = props;
	const openBankingCount = connections?.connectionsCount.openBanking ?? 0;
	const ecommerceCount = (connections?.connectionsCount.ecommerce ?? []).filter(
		(e: any) => e.data > 0
	);
	const paymentsCount = (connections?.connectionsCount.payments ?? []).filter(
		(e: any) => e.data > 0
	);

	const isBankingConnected = openBankingCount > 0;
	const isEcommerceConnected =
		ecommerceCount?.length > 0 || paymentsCount?.length > 0;

	function isfinancialAssessmentsApproved() {
		return assessments?.some((a) => a.is_approved);
	}

	const cashAdvanceHistory = useMemo(
		() =>
			flattenDeep(
				assessments?.map((as: any) => {
					return as.available_cash_advance_options.map((a: any) => {
						return a.repayment_history;
					});
				})
			),
		[assessments]
	);

	const cashAdvances = useMemo(() => {
		const cashArr = [] as any;

		(assessments?.slice() ?? [])
			.sort((a: any, b: any) =>
				dayjs(a.payment_date).isBefore(dayjs(b.payment_date)) ? 1 : -1
			)
			.filter((a) => a.is_approved)
			.forEach((approved) => {
				cashArr.push(...(approved.available_cash_advance_options ?? []));
			});
		return cashArr;
	}, [assessments]);

	const borrowed = cashAdvances.filter((ca: any) =>
		["disbursed"].includes(ca.status.id.toLowerCase())
	)[0];

	let borrowedAmount,
		paidAdvances,
		flatFee,
		flatFeePercentage,
		repaymentPercentage = 0;

	if (borrowed) {
		flatFee = borrowed.percentage_fee * borrowed.amount || 0;
		flatFeePercentage = borrowed.percentage_fee * 100 || 0;
		borrowedAmount = (borrowed.amount || 0) + flatFee;
		repaymentPercentage = borrowed.repayment_percentage || 0;
		paidAdvances = (borrowed.repayment_history || [])
			.map((rh: any) => rh.amount)
			.reduce((a: any, inc: number) => a + inc, 0);
	}

	const outStandingAdvances = borrowedAmount - paidAdvances;
	const [showModal, toggleModal] = useState(false);

	function checkConnectionsState(): string | number | null {
		let stage: number | null = null;
		const financialAssessmentsApproved = isfinancialAssessmentsApproved();
		const eitherConnectionsDone = isBankingConnected || isEcommerceConnected;

		if (!eitherConnectionsDone) return 0;
		if (!financialAssessmentsApproved) return 'fv';
		if (
			!financialAssessmentsApproved &&
			cashAdvances.some((ca: any) =>
				["selected"].includes(ca.status.id.toLowerCase())
			)
		)
			stage = 1;

		if (
			financialAssessmentsApproved &&
			!cashAdvances.some((ca: any) =>
				["selected"].includes(ca.status.id.toLowerCase())
			)
			&& cashAdvances.length > 0
		)
			stage = 2;

		if (
			financialAssessmentsApproved &&
			cashAdvances.some((ca: any) =>
				["selected"].includes(ca.status.id.toLowerCase())
			)
		)
			stage = 3;

		return stage;
	}

	const connectionState = checkConnectionsState();

	return (
		<div className='grid col-span-12 md:gap-y-8 lg:gap-y-8 max-w-6xl'>
			<CashAdvanceFinancingOptionsModal
				show={showModal}
				organisation={props.organisation}
				toggleModal={toggleModal}
				instantSettlementOptionActive={instantSettlementOptionActive}
			/>

			{/* If connections|open-banking pending  */}
			{connectionState === 0 && (
				<AlertConnectionsComponent>
					<p className='text-custom-dark text-sm md:text-md lg:text-md font-firma-light flex-1 leading-6'>
						Welcome to your Lenkie dashboard. Please complete your application
						by connecting your{" "}
						<Link
							className='text-sm md:text-md lg:text-md font-firma text-custom-dark underline'
							to='/onboarding/connections'
						>
							accounts
						</Link>{" "}
						to access financing options for your business.
					</p>
				</AlertConnectionsComponent>
			)}

			{/* If financial assessments not approved  */}
			{connectionState === 'fv' && (
				<AlertConnectionsComponent>
					<p className='text-custom-dark text-sm md:text-md lg:text-md font-firma-light flex-1 leading-6'>
						You are not eligible for this service because our assement of your financial records do not meet our requirement for accessing this service.
					</p>
				</AlertConnectionsComponent>
			)}

			{/* If connections|open-banking connected but not approved  */}
			{connectionState === 1 && (
				<AlertConnectionsComponent close={true}>
					<div className='grid gap-y-2 flex-1'>
						<h3 className='md:text-xl lg:text-xl text-md text-custom-dark'>
							Thank you for your application.
						</h3>
						<p className='text-custom-dark text-sm md:text-md lg:text-md font-firma-light'>
							We are now evaluating your application and you will receive
							feedback within 48 hours.
						</p>
					</div>
				</AlertConnectionsComponent>
			)}

			{/* If connections all completed  */}
			{connectionState === 2 && (
				<AlertConnectionsComponent close={true}>
					<p className='text-custom-dark text-sm md:text-md lg:text-md font-firma-light flex-1 leading-6'>
						Great news, your Lenkie Cash Advance application has been approved.
						We have provided you with a few financing options. Please{" "}
						<button
							className='underline text-sm md:text-md lg:text-md font-firma-light'
							onClick={() => toggleModal(true)}
						>
							select
						</button>{" "}
						the one that best suits your business needs.
					</p>
				</AlertConnectionsComponent>
			)}

			{/* If selected an option */}
			{connectionState === 3 && (
				<AlertConnectionsComponent>
					<div className='grid gap-y-2 flex-1'>
						<h3 className='md:text-xl lg:text-xl text-md text-custom-dark'>
							Thank you for selecting your offer.
						</h3>
						<p className='text-custom-dark md:text-md lg:text-md font-firma-light mdx:leading-6'>
							We will now send you the documentation to complete the process.
							Once completed, the funds would be deposited in your account on
							the activation date.
						</p>
					</div>
				</AlertConnectionsComponent>
			)}

			{connectionState === 2 &&
				<div className='col-span-12 flex items-end justify-end'>
					<button
						onClick={() => toggleModal(true)}
						className='flex items-center gap-x-2 cursor-pointer w-fit text-white font-firma-light text-xs text-center bg-custom-dark py-4 px-8 rounded-lg'
					>
						View financing options{" "}
						<span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-4 w-4'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='1.5'
									d='M9 5l7 7-7 7'
								/>
							</svg>
						</span>
					</button>
				</div>
			}

			<div className='grid grid-cols-12 col-span-12 gap-x-4 md:gap-x-8 lg:gap-x-8 md:gap-y-0 gap-y-4 mt-4 mdx:mt-0'>
				<div
					className='
            md:col-span-4
						lg:col-span-4
            col-span-12
            border border-gray-200
            md:h-32
            rounded-2xl
            flex
            items-center
            justify-start
            py-4
            pl-8
            gap-x-8
          '
				>
					<svg
						className='hidden xl:block'
						width='60'
						height='60'
						viewBox='0 0 60 60'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							width='60'
							height='60'
							rx='30'
							fill='#A6D4FF'
							fillOpacity='0.21'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M35.7689 26.3818H40C40 22.9846 37.9644 21 34.5156 21H25.4844C22.0356 21 20 22.9846 20 26.3385V33.6615C20 37.0154 22.0356 39 25.4844 39H34.5156C37.9644 39 40 37.0154 40 33.6615V33.3495H35.7689C33.8052 33.3495 32.2133 31.7975 32.2133 29.883C32.2133 27.9685 33.8052 26.4165 35.7689 26.4165V26.3818ZM35.7689 27.8724H39.2533C39.6657 27.8724 40 28.1983 40 28.6004V31.131C39.9952 31.5311 39.6637 31.8543 39.2533 31.8589H35.8489C34.8548 31.872 33.9855 31.2084 33.76 30.2643C33.6471 29.6783 33.8056 29.0736 34.1931 28.6122C34.5805 28.1509 35.1573 27.8801 35.7689 27.8724ZM35.92 30.533H36.2489C36.6711 30.533 37.0133 30.1993 37.0133 29.7877C37.0133 29.3761 36.6711 29.0424 36.2489 29.0424H35.92C35.7181 29.0401 35.5236 29.1166 35.38 29.255C35.2364 29.3934 35.1555 29.5821 35.1556 29.779C35.1555 30.1921 35.4964 30.5282 35.92 30.533ZM24.7378 26.3818H30.3822C30.8044 26.3818 31.1467 26.0481 31.1467 25.6365C31.1467 25.2249 30.8044 24.8912 30.3822 24.8912H24.7378C24.319 24.8912 23.9782 25.2196 23.9733 25.6278C23.9733 26.0409 24.3142 26.3771 24.7378 26.3818Z'
							fill='#1A2F61'
						/>
					</svg>

					<div className='grid'>
						<span className='font-firma text-custom-dark text-sm'>
							Total Repayable Amount
						</span>
						<p className='text-2xl font-firma-semibold'>
							<span className='font-firma-semibold mr-2 text-2xl'>£</span>
							{numeral(borrowedAmount).format("0,0")}
							{typeof flatFee === "number" && (flatFee as number) > 0 && (
								<sup className='text-xs font-firma-light italic text-gray-600 tracking-tighter ml-1'>
									(incl. {flatFeePercentage}% flat fee)
								</sup>
							)}
						</p>
					</div>
				</div>
				<div
					className='
            md:col-span-4
						lg:col-span-4
            col-span-12
            border border-gray-200
            md:h-32
            rounded-2xl
            flex
            items-center
            justify-start
            py-4
            pl-8
            gap-x-8
          '
				>
					<svg
						className='hidden xl:block'
						width='60'
						height='60'
						viewBox='0 0 60 60'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							width='60'
							height='60'
							rx='30'
							fill='#13BE6D'
							fillOpacity='0.08'
						/>
						<path
							d='M23 32C21.98 32 21.04 32.39 20.33 33.03C20.17 33.17 20.02 33.32 19.89 33.49C19.33 34.18 19 35.05 19 36C19 38.21 20.8 40 23 40C23.96 40 24.83 39.66 25.52 39.11C26.08 38.64 26.52 38.03 26.76 37.33C26.92 36.92 27 36.47 27 36C27 33.8 25.21 32 23 32ZM25.4 35.23C25.4 35.51 25.18 35.72 24.91 35.72C24.64 35.72 24.42 35.51 24.42 35.23V35.13C24.42 35.02 24.34 34.93 24.23 34.93H22.23C22.33 35.12 22.31 35.35 22.15 35.51C22.06 35.6 21.94 35.65 21.8 35.65C21.68 35.65 21.55 35.6 21.46 35.51L20.74 34.79C20.7 34.75 20.66 34.69 20.63 34.63C20.59 34.51 20.59 34.38 20.63 34.26C20.66 34.21 20.69 34.14 20.74 34.1L21.46 33.39C21.65 33.19 21.96 33.19 22.14 33.39C22.3 33.54 22.32 33.78 22.22 33.96H24.23C24.87 33.96 25.39 34.48 25.39 35.13V35.23H25.4ZM24.2 38.76C24.07 38.76 23.94 38.72 23.85 38.62C23.69 38.47 23.67 38.23 23.77 38.05H21.76C21.12 38.05 20.6 37.53 20.6 36.88V36.78C20.6 36.5 20.82 36.29 21.09 36.29C21.36 36.29 21.58 36.5 21.58 36.78V36.88C21.58 36.99 21.66 37.08 21.77 37.08H23.78C23.68 36.89 23.7 36.66 23.86 36.5C24.05 36.31 24.36 36.31 24.54 36.5L25.26 37.22C25.3 37.26 25.34 37.32 25.37 37.38C25.41 37.5 25.41 37.63 25.37 37.75C25.34 37.8 25.3 37.86 25.26 37.9L24.54 38.61C24.45 38.71 24.32 38.76 24.2 38.76Z'
							fill='#0A884C'
						/>
						<path
							d='M32.85 20.95V24.75H31.35V20.95C31.35 20.68 31.11 20.55 30.95 20.55C30.9 20.55 30.85 20.56 30.8 20.58L22.87 23.57C22.34 23.77 22 24.27 22 24.84V25.51C21.09 26.19 20.5 27.28 20.5 28.51V24.84C20.5 23.65 21.23 22.59 22.34 22.17L30.28 19.17C30.5 19.09 30.73 19.05 30.95 19.05C31.95 19.05 32.85 19.86 32.85 20.95Z'
							fill='#0A884C'
						/>
						<path
							d='M39.5001 31.5V32.5C39.5001 32.77 39.2901 32.99 39.0101 33H37.5501C37.0201 33 36.5401 32.61 36.5001 32.09C36.4701 31.78 36.5901 31.49 36.7901 31.29C36.9701 31.1 37.2201 31 37.4901 31H39.0001C39.2901 31.01 39.5001 31.23 39.5001 31.5Z'
							fill='#0A884C'
						/>
						<path
							d='M37.48 29.95H38.5C39.05 29.95 39.5 29.5 39.5 28.95V28.51C39.5 26.44 37.81 24.75 35.74 24.75H24.26C23.41 24.75 22.63 25.03 22 25.51C21.09 26.19 20.5 27.28 20.5 28.51V30.29C20.5 30.67 20.9 30.91 21.26 30.79C21.82 30.6 22.41 30.5 23 30.5C26.03 30.5 28.5 32.97 28.5 36C28.5 36.72 28.31 37.51 28.01 38.21C27.85 38.57 28.1 39 28.49 39H35.74C37.81 39 39.5 37.31 39.5 35.24V35.05C39.5 34.5 39.05 34.05 38.5 34.05H37.63C36.67 34.05 35.75 33.46 35.5 32.53C35.3 31.77 35.54 31.03 36.04 30.55C36.41 30.17 36.92 29.95 37.48 29.95ZM32 29.75H27C26.59 29.75 26.25 29.41 26.25 29C26.25 28.59 26.59 28.25 27 28.25H32C32.41 28.25 32.75 28.59 32.75 29C32.75 29.41 32.41 29.75 32 29.75Z'
							fill='#0A884C'
						/>
					</svg>

					<div className='grid'>
						<p className='font-firma text-custom-dark text-sm'>
							Outstanding Amount
						</p>
						<p className='text-2xl font-firma-semibold'>
							<span className='font-firma-semibold mr-2 text-2xl'>£</span>
							{numeral(outStandingAdvances).format("0,0")}
						</p>
					</div>
				</div>
				<div
					className='
            md:col-span-4
						lg:col-span-4
            col-span-12
            border border-gray-200
            md:h-32
            rounded-2xl
            flex
            items-center
            justify-start
            py-4
            pl-8
            gap-x-8
          '
				>
					<svg
						className='hidden xl:block'
						width='60'
						height='60'
						viewBox='0 0 60 60'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<rect
							width='60'
							height='60'
							rx='30'
							fill='#E91E63'
							fillOpacity='0.1'
						/>
						<path
							d='M34.19 20H25.81C22.17 20 20 22.17 20 25.81V34.18C20 37.83 22.17 40 25.81 40H34.18C37.82 40 39.99 37.83 39.99 34.19V25.81C40 22.17 37.83 20 34.19 20ZM25.75 27.14C25.75 26.33 26.41 25.66 27.23 25.66C28.04 25.66 28.71 26.32 28.71 27.14C28.71 27.95 28.05 28.62 27.23 28.62C26.41 28.61 25.75 27.95 25.75 27.14ZM26.82 34.02C26.63 34.02 26.44 33.95 26.29 33.8C26 33.51 26 33.04 26.29 32.74L32.84 26.19C33.13 25.9 33.61 25.9 33.9 26.19C34.19 26.48 34.19 26.96 33.9 27.25L27.35 33.8C27.2 33.95 27.01 34.02 26.82 34.02ZM33.77 34.34C32.96 34.34 32.29 33.68 32.29 32.86C32.29 32.05 32.95 31.38 33.77 31.38C34.58 31.38 35.25 32.04 35.25 32.86C35.25 33.68 34.59 34.34 33.77 34.34Z'
							fill='#EC457E'
						/>
					</svg>

					<div className='grid'>
						<p className='font-firma text-custom-dark text-sm'>
							Revenue Repayment
						</p>
						<p className='text-2xl font-firma-semibold'>
							{numeral(repaymentPercentage * 100).format("0")}
							<sup className='text-sm font-firma-semibold ml-1'>%</sup>
						</p>
					</div>
				</div>
			</div>

			<div className='mt-8 grid col-span-12'>
				<legend className='text-xl text-custom-dark font-firma-semibold col-span-12'>
					Repayment history
				</legend>
				{cashAdvanceHistory.length < 1 ? (
					<div className='grid col-span-12 items-center'>
						<ContentDisplayTableNoneComponent>
							<svg
								className='mx-auto md:h-48 md:w-48 h-36 w-36'
								width='189'
								height='189'
								viewBox='0 0 189 189'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle
									cx='94.5'
									cy='94.5'
									r='94.5'
									fill='#1A2F61'
									fillOpacity='0.08'
								/>
								<path
									d='M52.9102 86.7959H136.243'
									stroke='#1A2D5B'
									strokeWidth='1.5'
									strokeMiterlimit='10'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M92.7435 130.539H71.4516C56.66 130.539 52.9102 126.872 52.9102 112.247V77.9971C52.9102 64.7471 55.9934 60.4971 67.6184 59.8304C68.785 59.7888 70.0766 59.7471 71.4516 59.7471H117.743C132.535 59.7471 136.285 63.4137 136.285 78.0387V96.4138'
									stroke='#1A2D5B'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M119.577 136.796C128.782 136.796 136.243 129.334 136.243 120.129C136.243 110.924 128.782 103.463 119.577 103.463C110.372 103.463 102.91 110.924 102.91 120.129C102.91 129.334 110.372 136.796 119.577 136.796Z'
									stroke='#1A2D5B'
									strokeWidth='1.5'
									strokeMiterlimit='10'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M124.039 124.796L115.247 115.963'
									stroke='#1A2D5B'
									strokeWidth='1.5'
									strokeMiterlimit='10'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M123.951 116.085L115.117 124.877'
									stroke='#1A2D5B'
									strokeWidth='1.5'
									strokeMiterlimit='10'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M69.5768 111.796H86.2435'
									stroke='#1A2D5B'
									strokeWidth='1.5'
									strokeMiterlimit='10'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>

							<h3 className='mx-auto mt-8 text-sm text-custom-gray-3 font-firma-light text-center'>
								<span className='text-sm text-custom-dark font-firma-light'>
									No transactions yet.
								</span>
								<br />
								Your transactions will appear once you have recieved funding.
							</h3>

							{/* {connectionState !== 3 && (
								<Link
									to='/financing-options'
									className='mx-auto mt-8 w-fit text-white px-8 py-4 rounded-xl bg-custom-dark text-sm font-firma-light flex gap-x-4 items-center'
								>
									Explore financing options
									<span>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-4 w-4'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='1.5'
												d='M9 5l7 7-7 7'
											/>
										</svg>
									</span>
								</Link>
							)} */}
						</ContentDisplayTableNoneComponent>
					</div>
				) : (
					<TableHomeHistoryGrowthCapitalComponent
						tableData={cashAdvanceHistory}
						borrowedAmount={borrowedAmount}
						repaymentPercentage={repaymentPercentage * 100}
					/>
				)}
			</div>
		</div>
	);
}
