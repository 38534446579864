/// <reference path="../../../definition.d.ts" />
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { OnboardingConnectionOptions } from "../../../definition";
import { PAYMENT_PLATFORMS } from "../../constants";
import { rutterService } from "../../services/rutter";
import { toast } from "../../utils/toast";
import { FullLogo, FullLogoInverted } from "../shared/logo-full";
import { ModalComponent } from "../shared/modal";

const rutter = (window as any).Rutter;

interface Props {
	showModal: boolean;
	toggleModal: Function;
	person?: any;
	organisation?: any;
	connections: {
		ecommerceCount: any;
		paymentsCount: any;
	};
	mixpanel: any;
	connectedPayments?: string[];
	modal?: any;
	stripeRedirect?: string;
}

export function EcommercePaymentsComponent(props: Props) {
	const {
		mixpanel,
		showModal,
		toggleModal,
		organisation,
		modal = {},
		connectedPayments,
		stripeRedirect,
	} = props;
	const { register, handleSubmit, clearErrors } = useForm({});

	const [platforms, setPlatforms] = useState([] as any);

	const [sellerId] = useState(organisation.id);
	const [isMultiple, setIsMultiple] = useState(false);
	const [accountChosen, setAccountChosen] = useState(
		{} as OnboardingConnectionOptions
	);

	useEffect(() => {
		function isRegisteredAlready(pl: string) {
			const {
				connections: { paymentsCount },
			} = props;
			return (paymentsCount ?? []).find((count: any) => {
				return (
					pl.replaceAll("_", "").toLowerCase() ===
					count.platform.replaceAll("_", "").toLowerCase()
				);
			});
		}

		if (platforms.length === 0) {
			setPlatforms(
				PAYMENT_PLATFORMS.map((pl) => ({
					...pl,
					connections: isRegisteredAlready(pl.platform as string),
				}))
			);
		}
	}, [platforms, props]);

	function searchEcommercePlatforms({ term }: any) {
		setPlatforms(
			PAYMENT_PLATFORMS.map((pl: any) => ({
				...pl,
				title: pl.title.toLowerCase(),
			})).filter((pl: any) => pl.title.indexOf(term) !== -1)
		);
	}

	function resetField(evt: any) {
		evt.persist();

		if (evt.target?.value.length === 0 || evt.target?.value === "") {
			clearErrors();
			setPlatforms(PAYMENT_PLATFORMS as any);
		}
	}

	async function connect(pl: OnboardingConnectionOptions): Promise<void> {
		if ((pl.connections?.data ?? 0) > 1) {
			setAccountChosen(pl);
			return setIsMultiple(true);
		}
		doConnect(pl);
	}

	async function doConnect(
		pl: OnboardingConnectionOptions & any
	): Promise<void> {
		await mixpanel.track("Connect Platform", {
			Platform: pl.title,
			"Platform Type": "Payments",
			"Connection Status": "Opened",
		});

		if (typeof pl.link === "string") {
			if (pl.title === 'Stripe' && typeof stripeRedirect === 'string') {
				localStorage.setItem('stripeRedirect', stripeRedirect);
			}
			return (window.location.href = pl.link);
		}

		const rutterInstance = rutter.create({
			publicKey: process.env.REACT_APP_RUTTER_PUBLIC_KEY,
			onSuccess: async (publicToken: any) => {
				await mixpanel.track(`Connect ${pl.title} Platform - Opened`, {
					Platform: pl.title,
					"Platform Type": "Payments",
					"Connection Status": "Connected",
				});

				try {
					await rutterService.exchangeToken(publicToken, sellerId);
					setPlatforms(
						platforms.map((p: any) => {
							return {
								...p,
								data: {
									...p.connections.data,
									...(p.title === pl.title
										? { data: (pl.connections.data += 1) }
										: {}),
								},
							};
						})
					);
					toast({
						text: `Your ${pl.title} account connected successfully.`,
						type: "info",
					});
					toggleModal(false);
					await mixpanel.track(`Connect ${pl.title} Platform - Success`, {
						Platform: pl.title,
						"Platform Type": "Payments",
						"Connection Status": "Connected",
						"Verification Status": "Success",
					});
					toggleModal(false);
				} catch (error) {
					toast({
						text: `Failed to connect to your ${pl.title} account.`,
						type: "error",
					});
					await mixpanel.track(`Connect ${pl.title} Platform - Failed`, {
						Platform: pl.title,
						"Platform Type": "Payments",
						"Connection Status": "Connected",
						"Verification Status": "Failed",
					});
				}
			},
			onError: async (_: any) => {
				await mixpanel.track(`Connect ${pl.title} Platform - Failed`, {
					Platform: pl.title,
					"Platform Type": "Payments",
					"Connection Status": "Failed",
				});
			},
		});

		rutterInstance.open({
			platform: pl.platform,
		});
	}

	function handleCancelled(): void {
		if (isMultiple) {
			return setIsMultiple(false);
		}

		toggleModal(false);
	}

	return (
		<ModalComponent
			show={showModal}
			overflow={true}
			className='max-w-3xl w-full h-auto top-40 absolute mx-auto xs:w-11/12'
		>
			<div
				style={{
					minHeight: "35rem",
					maxHeight: "fit-content",
				}}
				className='grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 rounded-lg w-full h-full overflow-hidden'
			>
				<div className='bg-custom-dark h-full sm:col-span-1 md:col-span-1 lg:col-span-1 px-8 py-12 sm:flex md:flex lg:flex flex-col hidden'>
					<div className='flex items-center'>
						<FullLogoInverted className='h-6 mx-auto' />
					</div>
					<div className='flex items-center flex-grow'>
						<div className='flex flex-col justify-center items-center'>
							<svg
								className='h-7'
								width='36'
								height='36'
								viewBox='0 0 36 36'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M24.7064 14.105V10.8815C24.6599 7.10303 21.5579 4.07903 17.7809 4.12553C14.0804 4.17203 11.0879 7.15103 11.0249 10.8515V14.105'
									stroke='#fff'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M17.8655 21.2344V24.5659'
									stroke='#fff'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M17.8655 13.2363C9.24799 13.2363 6.37549 15.5883 6.37549 22.6428C6.37549 29.6988 9.24799 32.0508 17.8655 32.0508C26.483 32.0508 29.357 29.6988 29.357 22.6428C29.357 15.5883 26.483 13.2363 17.8655 13.2363Z'
									stroke='#fff'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>

							<h3 className='text-white text-sm text-center mt-2'>
								THIS CONNECTION IS SECURE.
							</h3>
						</div>
					</div>
					<div className='flex items-center justify-center'>
						<button
							onClick={handleCancelled}
							className='font-firma-medium tracking-wide border border-white text-white mt-8 px-6 py-3 text-xs rounded-lg'
						>
							{modal.cancelButtonText || "Cancel and go back"}
						</button>
					</div>
				</div>
				{isMultiple && (
					<div className='col-span-2 sm:col-span-2 md:col-span-2 px-8 py-8 sm:py-12 md:py-12 lg:py-12 relative'>
						<div className='mb-8 block sm:hidden md:hidden lg:hidden'>
							<FullLogo className='h-6 mx-auto' />
						</div>
						<legend className='font-firma-bold text-custom-dark text-2xl col-span-1'>
							Connect multiple accounts
						</legend>
						<p className='font-firma-light text-custom-dark text-sm col-span-1'>
							You already have an account connected to {accountChosen.title}.
						</p>

						<div className='mt-8 bottom-0 mb-8 absolute'>
							<div className='flex flex-row sm:flex-row md:flex-row lg:flex-row mt-8 gap-4 sm:gap-x-4 md:gap-x-4 lg:gap-x-4'>
								<button
									onClick={() => doConnect(accountChosen)}
									className='py-3 px-8 w-fit text-sm bg-custom-dark rounded-lg text-white'
								>
									Proceed
								</button>
								<button
									onClick={handleCancelled}
									className='font-firma-medium tracking-wide border border-custom-dark text-custom-dark px-8 py-3 text-sm rounded-lg block sm:hidden md:hidden lg:hidden'
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				)}
				{!isMultiple && (
					<div className='col-span-2 sm:col-span-2 md:col-span-2 px-8 py-8 sm:py-12 md:py-12 lg:py-12 flex flex-col'>
						<div className='mb-8 block sm:hidden md:hidden lg:hidden'>
							<FullLogo className='h-6 mx-auto' />
						</div>
						<legend className='font-firma-bold text-custom-dark text-2xl col-span-1'>
							Choose payment software
						</legend>
						<p className='font-firma-light text-custom-dark text-sm col-span-1'>
							Select all your payment software accounts so we can get you the
							most capital possible.
						</p>

						<div className='mt-8 input text-center'>
							<form onSubmit={handleSubmit(searchEcommercePlatforms)}>
								<div className='mt-1 relative rounded-lg custom'>
									<div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
										<span className='text-gray-500 sm:text-sm'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-6 w-6'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='1'
													d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
												/>
											</svg>
										</span>
									</div>
									<input
										{...register("term", { required: true })}
										type='text'
										className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-custom-darker px-4 py-3 outline-none'
										placeholder={`Search ${
											PAYMENT_PLATFORMS.length || 1
										} platforms`}
										onKeyUp={resetField}
									/>
								</div>
							</form>
						</div>

						<div className='pb-48 mt-8 gap-4 overflow-y-scroll flex-grow-0 max-h-80 grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3'>
							{platforms.map((pl: OnboardingConnectionOptions, key: number) => {
								const file = pl.title.toLowerCase().replaceAll(" ", "-");
								return (
									<button
										key={key}
										className='col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 h-24 sm:h-32 md:h-32 lg:h-32 w-full border border-custom-gray-light rounded-lg object-contain object-center relative overflow-hidden hover:border-custom-darker'
										onClick={() => connect(pl)}
									>
										<img
											className={`object-contain mx-auto ${
												file === "square" ? "h-5/12 w-5/12" : "h-8/12 w-8/12"
											}`}
											src={`/icons/ic-${file}.svg`}
											alt={pl.title}
										/>
										<span
											className={
												"text-custom-dark absolute top-2 right-2 " +
												(!(connectedPayments as any).includes(pl.title) &&
													" hidden")
											}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-5 w-5'
												viewBox='0 0 20 20'
												fill='currentColor'
											>
												<path
													fillRule='evenodd'
													d='M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
													clipRule='evenodd'
												/>
											</svg>
										</span>
									</button>
								);
							})}
						</div>

						<div className='flex gap-x-4 sm:hidden md:hidden lg:hidden mt-8'>
							<button
								onClick={handleCancelled}
								className='font-firma-medium tracking-wide border border-custom-dark text-custom-dark px-8 py-3 text-sm rounded-lg block sm:hidden md:hidden lg:hidden'
							>
								{modal.cancelButtonText || "Cancel and go back"}
							</button>
						</div>
					</div>
				)}
			</div>
		</ModalComponent>
	);
}
