import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FinancingAssessmentsState {
	loaded: boolean;
	assessments: any[];
}

const initialState: FinancingAssessmentsState = {
	loaded: false,
	assessments: [],
};

export const financingAssessmentsReducer = createSlice({
	name: "financingassessments",
	initialState,
	reducers: {
		financialAssessmentsDataLoaded: (state, action: PayloadAction<boolean>) => {
			state.loaded = action.payload;
		},
		setFinancialAssessments: (
			state,
			action: PayloadAction<{ [key: string]: any } | any>
		) => {
			state.loaded = true;
			state.assessments = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setFinancialAssessments } = financingAssessmentsReducer.actions;

export default financingAssessmentsReducer.reducer;
