import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyHouseOrganisation, Organisation } from "../models/organisation";

export interface OrganisationState {
	fetched: boolean;
	data: Organisation | CompanyHouseOrganisation;
}

const initialState: OrganisationState = {
	fetched: false,
	data: {
		href: undefined,
		id: undefined,
		version: undefined,
		trading_name: undefined,
		registered_name: undefined,
		registration_number: undefined,
		vat_id_number: undefined,
		founding_date: undefined,
		members: undefined,
		directors: undefined,
		website: undefined,
		physical_address: undefined,
		work_phone_number: undefined,
		other_phone_number: undefined,
		work_email_address: undefined,
		banking_details: undefined,
	},
};

export const organisationSlice = createSlice({
	name: "organisation",
	initialState,
	reducers: {
		setOrganisationData: (
			state,
			action: PayloadAction<{ [key: string]: any } | any>
		) => {
			state.fetched = true;
			state.data = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setOrganisationData } = organisationSlice.actions;

export default organisationSlice.reducer;
