import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ModalComponent } from "./shared/modal";
import { getAllCountriesAsArray } from "../utils";

interface Props {
	showModal: boolean;
	toggleModal: Function;
	authenticate: Function;
	person?: any;
	mixpanel: any;
}

const YEARS = [
	{
		name: "Less than 6 months",
		value: "na",
	},
	{
		name: "6 months to 1 year",
		value: 0.8,
	},
	{
		name: "1 - 2 years",
		value: 1.2,
	},
	{
		name: "2 - 5 years",
		value: 1.6,
	},
	{
		name: "Over 5 years",
		value: 2.2,
	},
];

export function GetStartedDetailsComponent({
	showModal,
	toggleModal,
	mixpanel,
	authenticate,
}: Props) {
	const {
		register,
		handleSubmit,
		clearErrors,
		reset,
		setError,
		watch,
		formState: { errors },
	} = useForm();

	const [stage, setStage] = useState("stage-1");
	const [errorMessage, setErrorMessage] = useState(null);

	async function navigateToOnboarding(): Promise<void> {
		await mixpanel.track("Redirect to sign in");
		await authenticate();
		localStorage.setItem("pqa", "done");
	}

	useEffect(() => {
		reset({
			years_of_operation: "null",
			company_name: "",
			company_website: "",
			average_monthly_revenue: 0,
			amount_to_borrow: 0,
			country: "GB",
		});
	}, [reset]);

	async function onSubmit(data: any) {
		try {
			clearErrors();
			if (+data.average_monthly_revenue < 4999) {
				return setError("average_monthly_revenue", {
					message: "Amount cannot be below £5,000.",
				});
			}

			if (data.years_of_operation === "null") {
				return setError("years_of_operation", {
					message: "Specify length of business operations.",
				});
			}

			if (data.years_of_operation === "null") {
				return setError("years_of_operation", {
					message: "Specify length of business operations.",
				});
			}

			if (data.country !== "GB" || data.years_of_operation === "na") {
				if (data.years_of_operation === "na") {
					setErrorMessage(
						"Unfortunately we require a minimum of 6 months trading history to provide a Cash Advance. Please check back in the future and we can help provide financing options." as any
					);
				}
				return setStage("stage-3");
			}

			// setAmount(+data.average_monthly_revenue * +data.years_of_operation);
			setStage("stage-2");
			await mixpanel.track("Pre qualification", data);
		} catch (error: Error | any) {
			mixpanel.track("Pre qualification error");
		}
	}

	function handleToggleModal() {
		toggleModal(false);
		reset({
			years_of_operation: "null",
			company_name: "",
			company_website: "",
			average_monthly_revenue: 0,
			amount_to_borrow: 0,
			country: "GB",
		});
		setStage("stage-1");
	}

	const watchMonthlyRevenue = watch("average_monthly_revenue", false);

	return (
		<>
			<ModalComponent
				show={showModal}
				hideModal={handleToggleModal}
				overflow={true}
				className='max-w-2xl h-auto top-20 absolute mx-auto w-11/12'
			>
				{stage === "stage-1" && (
					<div className='rounded-lg w-full px-8 py-12'>
						<legend className='text-2xl font-firma-semibold text-custom-dark'>
							How much funding are you eligible for?
						</legend>

						<form
							onSubmit={handleSubmit(onSubmit)}
							className='w-full grid grid-cols-12 gap-6 mt-12 contact'
						>
							<div className='input grid col-span-12 md:col-span-6 lg:col-span-6'>
								<label className='text-custom-dark font-firma-regular text-sm'>
									Company name
								</label>
								<div className='relative'>
									<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
										<span className='text-custom-dark sm:text-sm'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-4 w-4'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4'
												/>
											</svg>
										</span>
									</div>
									<input
										{...register("company_name", { required: true })}
										className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
										placeholder='Enter company name'
									/>
								</div>
								{errors.company_name && (
									<span className='text-red-600 text-sm font-firma mt-1'>
										{errors.company_name.message || "This field is required"}
									</span>
								)}
							</div>
							<div className='input grid col-span-12 md:col-span-6 lg:col-span-6'>
								<label className='text-custom-dark font-firma-regular text-sm'>
									Company website
								</label>
								<div className='relative'>
									<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
										<span className='text-custom-dark sm:text-sm'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-4 w-4'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
												/>
											</svg>
										</span>
									</div>
									<input
										{...register("company_website", {
											required: false,
											pattern: {
												value:
													/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
												message: "eg. www.website.com",
											},
										})}
										className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
										placeholder='Enter company website'
									/>
								</div>
								{errors.company_website && (
									<span className='text-red-600 text-sm font-firma mt-1'>
										{errors.company_website.message || "This field is required"}
									</span>
								)}
							</div>
							<div className='input grid col-span-12 md:col-span-6 lg:col-span-6'>
								<label className='text-custom-dark font-firma-regular text-sm'>
									Country of incorporation
								</label>
								<div className='relative'>
									<select
										{...register("country", {
											required: true,
										})}
										className='w-full border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3 pr-16'
									>
										{getAllCountriesAsArray().map((country: any) => (
											<option key={country.code} value={country.code}>
												{country.emoji}&nbsp;&nbsp;{country.name}
											</option>
										))}
									</select>
								</div>
								{errors.country && (
									<span className='text-red-600 text-sm font-firma mt-1'>
										{errors.country.message || "This field is required"}
									</span>
								)}
							</div>
							<div className='input grid col-span-12 md:col-span-6 lg:col-span-6'>
								<label className='text-custom-dark font-firma-regular text-sm'>
									Years in operation
								</label>
								<div className='relative'>
									<select
										{...register("years_of_operation", {
											required: true,
										})}
										className='w-full border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
									>
										<option value={"null"}>Choose</option>
										{YEARS.map((year: any, i: number) => (
											<option key={i} value={year.value}>
												{year.name}
											</option>
										))}
									</select>
								</div>
								{errors.years_of_operation && (
									<span className='text-red-600 text-sm font-firma mt-1'>
										{errors.years_of_operation.message ||
											"This field is required"}
									</span>
								)}
							</div>
							<div className='input grid col-span-12 md:col-span-6 lg:col-span-6'>
								<label className='text-custom-dark font-firma-regular text-sm'>
									Average monthly revenue (£)
								</label>
								<div className='relative'>
									<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
										<span className='text-custom-dark sm:text-sm'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-5 w-5'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='1.3'
													d='M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z'
												/>
											</svg>
										</span>
									</div>
									<input
										{...register("average_monthly_revenue", { required: true })}
										className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
										placeholder='Average monthly revenue'
										type='number'
									/>
								</div>
								{errors.average_monthly_revenue && (
									<span className='text-red-600 text-sm font-firma mt-1'>
										{errors.average_monthly_revenue.message ||
											"This field is required"}
									</span>
								)}
							</div>
							<div className='input grid col-span-12 md:col-span-6 lg:col-span-6'>
								<label className='text-custom-dark font-firma-regular text-sm'>
									Amount you want to borrow (£)
								</label>
								<div className='relative'>
									<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
										<span className='text-custom-dark sm:text-sm'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-5 w-5'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='1.3'
													d='M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z'
												/>
											</svg>
										</span>
									</div>
									<input
										{...register("amount_to_borrow", { required: true })}
										className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
										placeholder='Average monthly revenue'
										type='number'
									/>
								</div>
								{errors.amount_to_borrow && (
									<span className='text-red-600 text-sm font-firma mt-1'>
										{errors.amount_to_borrow.message ||
											"This field is required"}
									</span>
								)}
							</div>
							<div className='mt-8 col-span-12 flex w-full gap-x-8'>
								<button
									type='submit'
									disabled={+watchMonthlyRevenue < 100}
									className={
										"rounded-lg flex items-center gap-x-4 font-firma-light text-white w-fit px-8 py-4 " +
										(watchMonthlyRevenue < 100
											? "bg-gray-200 text-gray-500 cursor-not-allowed"
											: "bg-custom-dark text-white")
									}
								>
									Let's find out
									<span>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-4 w-4'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M14 5l7 7m0 0l-7 7m7-7H3'
											/>
										</svg>
									</span>
								</button>
							</div>
						</form>
					</div>
				)}

				{stage === "stage-2" && (
					<div className='rounded-lg w-full px-8 py-12'>
						<div className='bg-gray-50 rounded-lg p-8 text-center'>
							<h1 className='text-custom-dark text-xl font-firma-bold mt-4'>
								Good news!
							</h1>
							<h5 className='text-custom-dark font-firma-light'>
								You meet our initial criteria for lending.
							</h5>
							<h4 className='font-firma-light mt-4'>
								Please sign in and start connecting your accounts so we can
								prepare your financing offer.
							</h4>
							<div className='grid gap-y-4 w-full mt-16'>
								<button
									type='submit'
									className='rounded-lg flex items-center gap-x-4 font-firma-light bg-custom-dark text-white w-fit px-8 py-4 mx-auto text-sm'
									onClick={navigateToOnboarding as any}
								>
									Get funding
									<span>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-4 w-4'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M14 5l7 7m0 0l-7 7m7-7H3'
											/>
										</svg>
									</span>
								</button>
							</div>
						</div>
					</div>
				)}

				{stage === "stage-3" && (
					<div className='rounded-lg w-full px-8 py-12'>
						<div className='bg-red-50 rounded-lg p-8 text-center'>
							<h1 className='text-custom-dark text-xl font-firma-bold mt-4'>
								Not available!
							</h1>
							<h5 className='text-custom-dark font-firma-light mt-4'>
								{errorMessage ??
									"We cannot carry out your request due to inavailablity of our service in your current region."}
							</h5>
							<div className='grid gap-y-4 w-full mt-16'>
								<button
									className='rounded-lg flex items-center gap-x-4 font-firma-light bg-custom-dark text-white w-fit px-8 py-4 mx-auto text-sm'
									onClick={handleToggleModal as any}
								>
									Close
									<span>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-4 w-4'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M14 5l7 7m0 0l-7 7m7-7H3'
											/>
										</svg>
									</span>
								</button>
							</div>
						</div>
					</div>
				)}
			</ModalComponent>
		</>
	);
}
