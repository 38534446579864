import { NavLink } from "react-router-dom";

export const ViewGrowthCapitalOption = () => (
	<div className='w-full mt-16 lg:mt-8'>
		<div className='border border-gray-200 rounded-lg sm:p-16 px-4'>
			<legend className='font-firma-bold text-custom-dark sm:text-3xl text-xl'>
				Growth capital details
			</legend>
			<div className='grid grid-cols-5 sm:mt-16 mt-4 gap-y-2 sm:divide-x divide-y md:divide-y-0 divide-gray-200'>
				<div className="grid sm:col-span-1 pt-4 sm:pt-0 sm:justify-center col-span-5 gap-y-1">
					<label className="font-firma-light sm:text-md text-sm">Advance amount</label>
					<h1 className='font-firma-bold text-xl sm:text-2xl sm:text-center'>
						<sup className='font-firma-semibold mr-1 text-sm sm:text-lg'>£</sup>50,000
					</h1>
				</div>
				<div className="grid sm:col-span-1 pt-4 sm:pt-0 sm:justify-center col-span-5 gap-y-1">
					<label className="font-firma-light sm:text-md text-sm">Advance fee</label>
					<h1 className='font-firma-bold text-xl sm:text-2xl sm:text-center'>
						<sup className='font-firma-semibold mr-1 text-sm sm:text-lg'>£</sup>4,100
					</h1>
				</div>
				<div className="grid sm:col-span-1 pt-4 sm:pt-0 sm:justify-center col-span-5 gap-y-1">
					<label className="font-firma-light sm:text-md text-sm">
            Total Repayable amount
          </label>
					<h1 className='font-firma-bold text-xl sm:text-2xl sm:text-center'>
						<sup className='font-firma-semibold mr-1 text-sm sm:text-lg'>£</sup>54,100
					</h1>
				</div>
				<div className="grid sm:col-span-1 pt-4 sm:pt-0 sm:justify-center col-span-5 gap-y-1">
					<label className="font-firma-light sm:text-md text-sm">
            Est. repayment time
          </label>
					<h1 className='font-firma-bold text-xl sm:text-2xl sm:text-center'>
						5 months
					</h1>
				</div>
				<div className="grid sm:col-span-1 pt-4 sm:pt-0 sm:justify-center col-span-5 gap-y-1">
					<label className="font-firma-light sm:text-md text-sm">
            % monthly repayment
          </label>
					<h1 className='font-firma-bold text-xl sm:text-2xl sm:text-center'>
						15%
					</h1>
				</div>
			</div>

      <div className="sm:mt-16 mt-8">
        <legend className="font-firma-semibold text-custom-dark sm:text-3xl text-xl">Review process</legend>

        <div className="mt-4 text-lg max-w-2xl">
          <p className="font-firma-light">
            Please review the legal documentation for your cash advance and proceed with signing the contract once you are satisfied with the terms.
          </p>
          <p className="font-firma-light mt-2">
            Please note that cash advance is powered by Lenkie, a registered financial services provider. Therefore all contracts will be with Lenkie.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 sm:mt-12 my-6 sm:items-center">
          <button className="flex items-center text-center bg-custom-dark-lighter-3 cursor-not-allowed font-firma-light rounded-lg text-white py-4 px-6 sm:px-12 text-lg sm:text-xl w-fit">
            View terms
            <span className="ml-4">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
              </svg>
            </span>
          </button>
          <NavLink to="/settings#profile" className="text-red-700 font-firma-light underline text-sm">Complete profile setup in order to proceed</NavLink>
        </div>
      </div>
    </div>
	</div>
);
