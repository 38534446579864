import { AxiosInstance } from "axios";
import { openBankingApi } from "../utils";

export class Service {
	constructor(private http: AxiosInstance) {}

	public async getConnection(
		sellerId: string
	): Promise<any> {
		try {
			return await this.http.get<any>(`/connections/${sellerId}`);
		} catch (error) {
			return {};
		}
	}

	public async getConnectionsCount(
		sellerId: string
	): Promise<any> {
		try {
			return await this.http.get<any>(`/connections/${sellerId}/count`);
		} catch (error) {
			return {};
		}
	}

	public async exchangeToken(sellerId: string, code: string): Promise<any> {
		return await this.http.post(`/ExchangeToken/${sellerId}?authCode=${code}`);
	}
}

export const openBankingService = new Service(openBankingApi);
