/// <reference path="../../definition.d.ts" />

import { OnboardingConnectionOptions } from "../../definition";

export const ONBOARDING_CAPTIONS = {
	"/onboarding/contact": {
		caption: "Growth capital for businesses",
		sub: "We provide fast and flexible funding solutions to invest in marketing, inventory, staff, equipment or to simply to re-invest in your business.",
	},
	"/onboarding/business": {
		caption: "Repayments that give flexibility",
		sub: "We collect repayments as a fixed percentage of sales, meaning you pay down more when business is booming and less when it slows down.",
	},
	"/onboarding/connections": {
		caption: "Safe connection",
		sub: "We analyze your sales and banking data to perform an instant credit assessment. We will only use this for the purpose of your Lenkie application and won't share the data with any external parties.",
	},
};

export const ONBOARDING_DOCUMENT_TITLE: { [key: string]: string } = {
	contact: "Your contact information",
	business: "Enter your business details",
	connections: "Connect your bank and marketplace accounts",
};

export const ECOMMERCE_PLATFORMS = [
	{
		title: "Amazon",
		event: "amazon",
	},
	{
		title: "Shopify",
		event: "shopify",
	},
	{
		title: "Etsy",
		event: "etsy",
	},
	{
		title: "Prestashop",
		event: "prestashop",
	},
	{
		title: "Woo Commerce",
		event: "woo_commerce",
	},
	{
		title: "Big Commerce",
		event: "big_commerce",
	},
	{
		title: "Squarespace",
		event: "squarespace",
	},
	{
		title: "Ebay",
		event: "eBay",
	},
	{
		title: "Magento",
		event: "magento",
	},
].map((pl: OnboardingConnectionOptions) => ({
	...pl,
	event: "connect_" + pl.event.toLowerCase().replaceAll(" ", "_"),
	platform: pl.event.toUpperCase(),
})) as OnboardingConnectionOptions[];

export const PAYMENT_PLATFORMS = [
	{
		title: "Stripe",
		event: "stripe",
		link: process.env.REACT_APP_STRIPE_URL,
	},
	{
		title: "Square",
		event: "square",
	},
].map((pl: OnboardingConnectionOptions) => ({
	...pl,
	event: "connect_" + pl.event.toLowerCase().replaceAll(" ", "_"),
	platform: pl.event.toUpperCase(),
})) as OnboardingConnectionOptions[];
