import dayjs from "dayjs";

interface HomeTabProps {
	active: string;
	name: string;
	switchTab: Function;
	welcome?: boolean;
	hasMenu?: boolean;
}

export function GeneralPageTabComponent(props: HomeTabProps) {
	return (
		<div className='border-b border-gray-100' style={{ minHeight: '120.5px' }}>
			<div className='md:py-0 md:px-14 lg:px-14 px-4'>
				<h3 className='flex gap-x-2 text-3xl font-firma-semibold text-custom-dark'>
					{props.welcome && <span className='text-custom-dark text-3xl font-firma-semibold'>
						Welcome,{""}
					</span>}
					<span className="hidden md:block lg:block text-3xl font-firma-semibold text-custom-dark">
						{(props.name ?? '')}
					</span>
					<span className="md:hidden lg:hidden text-3xl font-firma-semibold text-custom-dark">
						{(props.name ?? '')}
					</span>
				</h3>
				<p className='text-sm font-firma text-custom-gray-2 opacity-70'>
					{dayjs().format("dddd, MMM D, YYYY")}
				</p>
			</div>
			{props.hasMenu && <ul className='flex flex-1 md:py-0 md:px-14 lg:px-14 px-4 overflow-x-scroll mt-7'>
				<li
					onClick={() => props.switchTab("growth-capital")}
					className={
						"font-firma-medium text-sm flex items-center gap-x-4 px-6 py-3 " +
						(props.active === "growth-capital"
							? "bg-gray-50 text-custom-dark border-b-4 border-custom-dark-negative-z cursor-default"
							: "cursor-pointer opacity-60")
					}
				>
					<span className='hidden sm:block md:block lg:block'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='18'
							height='18'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M13 7h8m0 0v8m0-8l-8 8-4-4-6 6'
							/>
						</svg>
					</span>
					<span className='text-sm mt-1'>Growth capital</span>
				</li>
				<li
					onClick={() => props.switchTab("instant-payout")}
					className={
						"font-firma-medium text-sm flex items-center gap-x-4 px-6 py-3 " +
						(props.active === "instant-payout"
							? "bg-gray-50 text-custom-dark border-b-4 border-custom-dark-negative-z cursor-default"
							: "cursor-pointer opacity-60")
					}
				>
					<span className='hidden sm:block md:block lg:block'>
						<svg
							className='h-4 w-4'
							width='18'
							height='18'
							viewBox='0 0 14 14'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M0.869141 9.31799C0.869141 9.31799 0.963807 10.4767 0.985807 10.842C1.01514 11.332 1.20447 11.8793 1.52047 12.2593C1.96647 12.798 2.49181 12.988 3.19314 12.9893C4.01781 12.9907 10.0145 12.9907 10.8391 12.9893C11.5405 12.988 12.0658 12.798 12.5118 12.2593C12.8278 11.8793 13.0171 11.332 13.0471 10.842C13.0685 10.4767 13.1631 9.31799 13.1631 9.31799'
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M4.66357 2.55307V2.30574C4.66357 1.49241 5.32224 0.83374 6.13557 0.83374H7.85691C8.66957 0.83374 9.32891 1.49241 9.32891 2.30574L9.32957 2.55307'
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M6.99642 10.1189V9.25623'
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M0.833008 4.59287V6.9042C2.11167 7.74753 3.64367 8.3382 5.32501 8.5722C5.52634 7.8382 6.18834 7.3002 6.99301 7.3002C7.78501 7.3002 8.46034 7.8382 8.64834 8.57887C10.3363 8.34487 11.8743 7.7542 13.1597 6.9042V4.59287C13.1597 3.46353 12.251 2.5542 11.1217 2.5542H2.87767C1.74834 2.5542 0.833008 3.46353 0.833008 4.59287Z'
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</span>
					<span className='text-sm mt-1'>Instant payout</span>
				</li>
			</ul>}
		</div>
	);
}
