import { AuthConsumer } from "../../providers/auth";

export const Logout = () => (
	<AuthConsumer>
		{({ logout }: any) => {
			logout();
			return <span>logout</span>;
		}}
	</AuthConsumer>
);
