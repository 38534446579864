import { Route } from "react-router-dom";
import { LayoutPreloaderWide } from "../components/layout-preloader-wide";
import { RootState, useAppSelector } from "../store";
import userManager from "../utils/user-manager";

interface RouteProps {
	[key: string]: any;
}

export const ProtectedRoute = (args: {} & RouteProps) => {
	const { component, ...rest }: RouteProps = args;
	const { user, isLoadingUser } =
		useAppSelector((state: RootState) => state.oidc) ?? {};
	
	if (user && !isLoadingUser) {
		return <Route {...rest} children={component} />;
	}

	if (!isLoadingUser && !user) {
		userManager.signinRedirect();
	}

	return <LayoutPreloaderWide />;
};
