import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

interface GrowthCapitalProps {
	person: { [key: string]: any };
	children?: any;
}

export const GrowthCapital = (props: GrowthCapitalProps & any) => {
	const { person = {} } = props;

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Lenkie | Growth Options</title>
			</Helmet>
			{person.id ? (
				<div className='w-full mt-16 lg:mt-8'>
					<div className='sm:px-0 md:px-0 lg:px-0 px-0 grid grid-cols-12 gap-y-8'>
						<div className='col-span-12 md:col-span-10 lg:col-span-7 row-start-2 md:row-start-1 lg:row-start-1 w-full'>
							<legend className='font-firma-bold text-custom-dark text-3xl'>
								Flexible capital to accelerate growth
							</legend>

							<div className='mt-8 grid gap-y-4'>
								<legend className='font-firma-semibold text-custom-dark text-xl'>
									Welcome {person.given_name},
								</legend>
								<p className='font-firma-light'>
									You can now start your journey to supercharge the growth of
									your business.
								</p>

								<NavLink
									to='/growth-capital/options'
									className='mt-8 bg-custom-dark w-fit rounded-lg text-white p-4 px-8 font-firma-light text-sm'
								>
									Explore Funding Options
								</NavLink>
							</div>
						</div>

						</div>
				</div>
			) : null}
		</>
	);
};
