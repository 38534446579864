import { useEffect, useState } from "react";
import { Country } from "../../../models/country";
import { countryService } from "../../../services/country";
import { getAllCountriesAsArray } from "../../../utils";
import { OnboardingBusinessAdvancedComponent } from "./business-advanced";
import { OnboardingBusinessManualComponent } from "./business-manual";

interface Props {
	callback: any;
}

export function OnboardingBusinessComponent(props: Props & any) {
	const { person, organisation } = props;
	const [fetched, setFetched] = useState(false);
	const [countries, setCountries] = useState([] as Country[]);
	const [mode, setMode] = useState("advanced");

	useEffect(() => {
		async function fetchData() {
			(async function () {
				await props.mixpanel.track("View Business Details");
			})();
			try {
				const [{ data: countryData }] = await Promise.all([
					await countryService.getCountries(),
				]);

				if (countryData) {
					setCountries(countryData);
				}

				setFetched(true);
			} catch (error) {}
		}

		if (!fetched) fetchData();
	}, [fetched, props.mixpanel]);

	function customizeCountries() {
		const allCountries = getAllCountriesAsArray();
		return countries.map((c: any) => {
			let country = { ...c };
			const fCountry = allCountries.find((c: any) => c.code === country.code);
			if (fCountry) {
				country = Object.assign(country, fCountry);
			}

			return country;
		});
	}

	return (
		<div className='max-w-lg w-full p-8 rounded-lg'>
			<legend className='font-firma-bold text-custom-dark text-3xl'>
				Business details
			</legend>

			<div className='mt-2'>
				<p className='font-firma-light text-grey-800 opacity-60 text-sm'>
					Please fill in your details in the fields below.
				</p>
			</div>

			{mode === "manual" && (
				<OnboardingBusinessManualComponent
					countries={customizeCountries()}
					person={person}
					organisation={organisation}
					setMode={setMode}
					{...props}
				/>
			)}

			{mode === "advanced" && (
				<OnboardingBusinessAdvancedComponent
					countries={customizeCountries()}
					person={person}
					organisation={organisation}
					setMode={setMode}
					{...props}
				/>
			)}
		</div>
	);
}
