import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "../constants/auth";

export default class AuthService {
	UserManager;
	user: any;

	constructor() {
		this.UserManager = new UserManager({
			...IDENTITY_CONFIG,
			userStore: new WebStorageStateStore({ store: window.sessionStorage }),
			metadata: {
				...METADATA_OIDC,
			},
		});

		if (window.location.hostname === "localhost") {
			Log.logger = console;
			Log.level = Log.DEBUG;
		}

		this.UserManager.events.addUserLoaded((user) => {
			if (window.location.href.indexOf("callback") !== -1) {
				this.navigateToScreen();
			}
		});

		this.UserManager.events.addSilentRenewError((e) => {
			this.signinRedirect()
		});

		this.UserManager.events.addAccessTokenExpired(() => {
			this.signinSilent();
		});
	}

	signinRedirectCallback = async () => {
		try {
			await this.UserManager.signinRedirectCallback();
			window.location.replace(process.env.REACT_APP_AUDIENCE_URL as string);
		} catch (error) {
			window.location.replace("/get-started");
		}
	};

	getUser = async () => {
		const user = await this.UserManager.getUser();
		if (!user) {
			return await this.UserManager.signinRedirectCallback();
		}
		this.user = user;
		return user;
	};

	parseJwt = (token: string) => {
		const base64Url = token.split(".")[1];
		const base64 = base64Url.replace("-", "+").replace("_", "/");
		return JSON.parse(window.atob(base64));
	};

	signinRedirect = () => {
		if (typeof localStorage.getItem("redirectUri") !== "string") {
			localStorage.setItem("redirectUri", window.location.pathname);
		}
		const platformId = new URLSearchParams(window.location.search).get(
			"platformId"
		);

		this.UserManager.signinRedirect({
			extraQueryParams: {
				platformId,
			},
		});
	};

	navigateToScreen = () => {
		window.location.replace(process.env.REACT_APP_AUDIENCE_URL as string);
	};

	isAuthenticated = () => {
		const sessionData = sessionStorage.getItem(
			`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
		);

		if (!sessionData) return false;
		const oidcStorage = JSON.parse(sessionData);
		const token = oidcStorage?.access_token;
		if (!token) return false;

		localStorage.setItem("lenkie_token", token);
		return true;
	};

	signinSilent = async () => {
		try {
			await this.UserManager.signinSilent();
			this.user = await this.getUser();
		} catch (error) {
			await this.createSigninRequest();
		}
	};

	signinSilentCallback = () => {
		this.UserManager.signinSilentCallback();
	};

	createSigninRequest = () => {
		return this.UserManager.createSigninRequest();
	};

	logout = () => {
		this.UserManager.signoutRedirect({
			id_token_hint: localStorage.getItem("lenkie_token"),
		});
		this.UserManager.clearStaleState();
	};

	signoutRedirectCallback = () => {
		this.UserManager.signoutRedirectCallback().then(() => {
			localStorage.clear();
			window.location.replace(process.env.REACT_APP_PUBLIC_URL || "");
		});
		this.UserManager.clearStaleState();
	};
}
