import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ConnectionsState {
	fetched: boolean;
	data: {
		connections: { [key: string]: any[] };
		connectionsCount: { [key: string]: any };
	}
}

const initialState: ConnectionsState = {
	fetched: false,
	data: {
		connections: {},
		connectionsCount: {},
	},
};

export const connectionsSlice = createSlice({
	name: "connections",
	initialState,
	reducers: {
		setConnectionsData: (
			state,
			action: PayloadAction<{ [key: string]: any } | any>
		) => {
			state.fetched = true;
			state.data = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setConnectionsData } = connectionsSlice.actions;

export default connectionsSlice.reducer;
