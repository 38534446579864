import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { organisationService } from "../../../services/organisation";
import { debounce } from "../../../utils/functions";
import { toast } from "../../../utils/toast";

export function OnboardingBusinessAdvancedComponent(props: any) {
	const { mixpanel } = props;
	const [results, setResults] = useState([]);
	const [showResults, setShowResults] = useState(false);
	const [organisation, setOrganisation] = useState({} as any);
	const [form, setForm] = useState({} as any);
	const [request, setRequest] = useState(false);
	const [searchNotFound, setSearchNotFound] = useState(false);

	useEffect(() => {
		if (props.organisation && !organisation.set) {
			const formatted = {
				set: true,
				...props.organisation,
				title: props.organisation.trading_name,
				company_number: props.organisation.registration_number,
				directors: props.organisation.directors?.map((d: any) => ({
					...d,
					name: `${d.given_name} ${d.family_name}`,
				})),
			};
			setOrganisation(formatted);
			const { directors, ...o } = formatted;
			setForm({
				organisation: o,
				directors,
			});
		}
	}, [props.organisation, organisation]);

	async function searchCompanyHouse(evt: any) {
		try {
			const value = evt.target?.value || "";
			if (!(value.length > 2)) return;

			setRequest(true);
			setSearchNotFound(false);
			const { data } = await organisationService.getCompaniesHouseOrganisation(
				value
			);
			if (data.length > 0) {
				setShowResults(true);
				setResults(data);
			} else {
				toast({
					text: "No company results available.",
					type: "error",
					time: 3,
				});
				setResults([]);
				setShowResults(false);
				setSearchNotFound(true);
			}
		} catch (error: Error | any) {
			if (axios.isCancel(error)) return;
			setSearchNotFound(true);
			toast({
				text:
					error.response?.message ||
					"Failed to retrieve available company results",
				type: "error",
				time: 3,
			});
		} finally {
			setRequest(false);
		}
	}

	async function chooseCompany(evt: any, results: any) {
		evt.preventDefault();

		const { directors, ...data } = results;
		setOrganisation({ ...results, set: true });
		setShowResults(false);

		const country = props.countries.find((c: any) => c.code === "GB");
		const payload = {
			...data,
			physical_address: {
				...data.address,
				country,
			},
		};
		const formattedOrganisation =
			organisationService.parseCompanyHouseOrganisationToFormat(payload);
		const formattedDirectors =
			organisationService.createDirectorPatchDocument(directors);

		setForm({
			directors: formattedDirectors,
			organisation: {
				...props.organisation,
				...formattedOrganisation,
			},
		});
	}

	const submitForm = async (evt: FormEvent) => {
		try {
			evt.preventDefault();
			const { organisation, directors } = form;

			if (organisation.set) {
				await mixpanel.track("Update Company Details", {
					status: "not-modified",
					screen: "Onboarding",
					path: window.location.pathname,
				});
				return props.history.push("/onboarding/connections");
			}

			const { status } = await organisationService.patchOrganisation(
				organisation.id,
				organisation,
				directors
			);

			if (status === 204) {
				toast({
					text: "Your business details have ben updated successfully.",
					type: "info",
					time: 3,
				});

				await mixpanel.setOnce({
					Company:
						organisation.trading_name || organisation.registration_number,
				});

				await mixpanel.track("Update Company Details", {
					status: "modified",
					screen: "Onboarding",
					path: window.location.pathname,
				});
				props.triggerRefreshData();
				return props.history.push("/onboarding/connections");
			}
			toast({
				text: "An error occurred while processing your request",
				type: "error",
				time: 3,
			});
			await mixpanel.track("Update Company Details", {
				status: "failed",
				screen: "Onboarding",
				path: window.location.pathname,
			});
		} catch (error: Error | any) {
			toast({
				text:
					error.response?.message ||
					"An error occurred while processing your request",
				type: "error",
				time: 3,
			});
			await mixpanel.track("Update Company Details", {
				status: "failed",
				screen: "Onboarding",
				path: window.location.pathname,
			});
		}
	};

	return (
		<div className='mt-8'>
			<h3 className='text-md font-firma-semibold text-custom-dark'>
				Find your business
			</h3>

			<form onSubmit={submitForm}>
				<div className='w-full grid gap-y-6 mt-8 business'>
					<div className='input inline-block relative'>
						<div className='grid'>
							<label className='text-custom-dark font-firma text-sm'>
								Company name/registration number
							</label>

							<div className='w-full relative'>
								<input
									className='border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 text-sm pl-4 pr-8 py-3 w-full'
									placeholder='Enter company name or registration number'
									onChange={debounce(searchCompanyHouse, 800, false)}
								/>
								<span className='absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none'>
									{request && (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='animate-spin h-4 w-4 text-custom-dark'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15'
											/>
										</svg>
									)}
									{searchNotFound && (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-4 w-4 text-customRed-100'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												strokeWidth='2'
												d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
											/>
										</svg>
									)}
								</span>
							</div>
						</div>
						<div
							className={`origin-bottom-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-scroll ${
								showResults
									? "transform opacity-100 scale-100"
									: "transform opacity-0 scale-95 hidden"
							}`}
							role='menu'
							aria-orientation='vertical'
							aria-labelledby='menu-button'
							tabIndex={-1}
							style={{ maxHeight: "300px" }}
						>
							<div className='py-1' role='none'>
								{results.map((r: any, i: number) => (
									<a
										href='#!'
										key={i}
										className='text-custom-dark text-left grid hover:bg-gray-200 w-full px-4 py-2 text-sm'
										role='menuitem'
										tabIndex={-1}
										id={"menu-item-" + i}
										onClick={(e) => chooseCompany(e, r)}
									>
										<span className='text-md font-firma-semibold'>
											{r.title}
										</span>
										<span className='text-xs text-gray-600 font-firma'>
											{r.company_number}
										</span>
									</a>
								))}
							</div>
						</div>
					</div>
					{organisation?.title && (
						<div
							style={{ maxHeight: "250px", overflow: "scroll" }}
							className='border-2 border-gray-300 rounded-lg text-gray-600 px-6 py-4'
						>
							<h3 className='font-firma-semibold text-lg'>
								{organisation.title}
							</h3>
							<div className='grid'>
								<h6 className='font-firma text-md'>
									{organisation.company_number}
								</h6>
								{(organisation.directors ?? []).length > 0 && (
									<div className='grid mt-2'>
										{organisation.directors
											.reverse()
											.map((d: any, i: number) => (
												<label key={i} className='text-sm flex font-firma'>
													{d.name}
												</label>
											))}
									</div>
								)}
								<h6 className='text-sm mt-1 font-firma-light tracking-wide'>
									{organisation.address_snippet ||
										organisation.physical_address?.street_address}
								</h6>
							</div>
						</div>
					)}
					{/* <a
						href='#!'
						onClick={toggleMode}
						className='text-right text-custom-dark font-firma-light text-xs'
					>
						Can’t find your business?
					</a> */}
				</div>
				<div className='grid text-center gap-y-4 mt-8'>
					<button
						disabled={typeof organisation?.title !== "string"}
						className='cursor-pointer bg-custom-dark text-white text-center font-firma rounded-lg p-4 sm:px-8 sm:mt-8 mt-4'
					>
						Continue
					</button>
					<Link
						className='underline text-sm w-fit mx-auto'
						to='/onboarding/contact'
					>
						back
					</Link>
				</div>
			</form>
		</div>
	);
}
