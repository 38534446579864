import axios from "axios";
import userManager from "./user-manager";

async function fetchToken() {
	const token = (await userManager.getUser())?.access_token;
	return "Bearer " + token;
}

const lenkieApi = axios.create({
	baseURL: process.env.REACT_APP_LENKIE_API_URL,
});

lenkieApi.interceptors.request.use(async function (config) {
	// Do something before request is sent
	config.headers.Authorization = await fetchToken()
	return config;
});

const rutterApi = axios.create({
	baseURL: process.env.REACT_APP_RUTTER_API,
});

rutterApi.interceptors.request.use(async function (config) {
	// Do something before request is sent
	config.headers.Authorization = await fetchToken()
	return config;
});

const openBankingApi = axios.create({
	baseURL: process.env.REACT_APP_OPEN_BANKING_API_URL,
});

openBankingApi.interceptors.request.use(async function (config) {
	// Do something before request is sent
	config.headers.Authorization = await fetchToken()
	return config;
});

const financeApi = axios.create({
	baseURL: process.env.REACT_APP_CASH_ADVANCE_API,
});

financeApi.interceptors.request.use(async function (config) {
	// Do something before request is sent
	config.headers.Authorization = await fetchToken()
	return config;
});

export { lenkieApi, rutterApi, openBankingApi, financeApi };
