import { useEffect, useState } from "react";
import numeral from "numeral";
import { PageTabComponent } from "../../components/page-tab";
import { financeApi } from "../../utils";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
	financialOptionsDataLoaded,
	setFinancialOptions,
	updateFinancialOption,
} from "../../reducers/financing-options";
import { ModalViewFinancingOptions } from "../../components/modal-view-financing-options";
import { toast } from "utils/toast";
import { Mixpanel } from "services/mixpanel";

export const FinancingOptions = (props: any) => {
	const financingOptions = useAppSelector(
		(state: RootState) => state.financingOptions
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		async function fetchGrowthOptions() {
			try {
				const { data } = await financeApi.get(
					`/CashAdvances/Seller/${props.organisation.id}`
				);
				dispatch(setFinancialOptions(data));
			} catch (error) {
			} finally {
				dispatch(financialOptionsDataLoaded(true));
			}
		}

		if (!financingOptions.loaded && props.organisation.id) fetchGrowthOptions();
	}, [dispatch, financingOptions.loaded, props.organisation.id]);

	const [financingOption, setFinancingOption] = useState<any | undefined>(
		undefined
	);

	function viewFinancingOption(option: any) {
		setFinancingOption(option);
	}

	const breadcrumbs = [
		{
			title: "Home",
			to: "/",
		},
		{
			title: "Financing options",
			active: true,
		},
	];

	async function acceptFinancialOption(params: any) {
		try {
			const id = financingOption?.id;
			if (typeof id !== "string") return;
			if (optionSelected() > 0) {
				return toast({
					text: "You are not allowed to request multiple cash advance options.",
					type: "error",
					position: "top",
				});
			}
			const response = await financeApi.put(`/CashAdvances/${id}/Status`, {
				id: "Selected",
			});
			toast({
				text: "Cash advance request successful.",
				type: "info",
				position: "top",
			});
			setFinancingOption(undefined);
			dispatch(updateFinancialOption(response.data));
			await Mixpanel.track("Cash Advance Option Selected", {
				option_id: id,
			});
		} catch (error) {
			toast({
				text: "An error occured while processing your request.",
				type: "error",
				position: "top",
			});
		}
	}

	function optionSelected(): number {
		return financingOptions.options.filter((op: any) =>
			["selected", "disbursed"].includes(op.status.id.toLowerCase())
		).length;
	}

	return (
		<>
			<PageTabComponent
				name={props.person?.given_name}
				breadcrumbs={breadcrumbs}
			>
				<>
					<h3 className='text-3xl font-firma-semibold text-custom-dark'>
						<span className='text-custom-dark text-2xl font-firma-semibold'>
							Financing options
						</span>
					</h3>
				</>
			</PageTabComponent>

			<ModalViewFinancingOptions
				show={typeof financingOption !== "undefined"}
				close={() => setFinancingOption(undefined)}
				accept={acceptFinancialOption}
				option={financingOption}
				selected={optionSelected() > 0}
			/>

			<div className='md:p-14 lg:p-14 p-4 h-auto'>
				<div className='w-full mt-8'>
					<div className='sm:px-0 md:px-0 lg:px-0 px-0'>
						<div className='sm:mt-16 mt-8 grid sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-8 max-w-6xl'>
							{financingOptions.options
								.slice()
								.sort((a: any, b: any) => b.amount - a.amount)
								.map((n: any, i) => {
									const percentageFee = n.percentage_fee;
									const percentageValue = percentageFee * n.amount;
									const totalRepayment = percentageValue + n.amount;
									return (
										<div
											key={i}
											className='lg:col-span-4 md:col-span-6 col-span-6 sm:p-8 md:p-8 lg:p-8 p-4 rounded-lg border border-gray-200 grid gap-y-8'
										>
											<div className='bg-custom-dark-negative-y p-4 rounded-lg flex justify-center flex-col gap-y-1'>
												<h4 className='font-firma-light'>
													Total advance amount
												</h4>
												<h1 className='font-firma-bold text-2xl text-custom-dark'>
													<span className='font-firma-semibold mr-1 text-2xl text-custom-dark'>
														£
													</span>
													{numeral(n.amount).format("0, 0")}
												</h1>
											</div>
											<div className='grid grid-cols-3 justify-center items-center flex-col gap-y-3 text-custom-dark'>
												<h4 className='font-firma-light col-span-2 text-sm'>
													Revenue repayment rate:
												</h4>
												<h1 className='font-firma-bold col-span-1 text-right'>
													{numeral(n.repayment_percentage * 100).format("0")}%
												</h1>
												<h4 className='font-firma-light col-span-2 text-sm'>
													Flat fee:
												</h4>
												<h1 className='font-firma-bold col-span-1 text-right'>
													{numeral(n.percentage_fee).format("0,0%")}
												</h1>
												<h4 className='font-firma-light col-span-2 text-sm'>
													Total repayment:
												</h4>
												<h1 className='font-firma-bold col-span-1 text-right'>
													<span className='font-firma-semibold mr-1 text-sm'>
														£
													</span>
													{numeral(totalRepayment).format("0,0")}
												</h1>
											</div>
											{n.status.id !== "Selected" && (
												<button
													className='text-center bg-custom-dark hover:bg-custom-darker font-firma-light rounded-lg text-white p-4 sm:px-8 text-md'
													onClick={() => viewFinancingOption(n)}
												>
													View details
												</button>
											)}
											{n.status.id === "Selected" && (
												<button
													className='text-center bg-custom-green font-firma-light rounded-lg text-white p-4 sm:px-8 text-sm'
													onClick={() => viewFinancingOption(n)}
												>
													<h3 className='font-firma flex items-center justify-center gap-x-4 text-md'>
														Selected
														<span className='text-white'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																className='h-5 w-5'
																fill='none'
																viewBox='0 0 24 24'
																stroke='currentColor'
															>
																<path
																	strokeLinecap='round'
																	strokeLinejoin='round'
																	strokeWidth='2'
																	d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
																/>
															</svg>
														</span>
													</h3>
												</button>
											)}
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
