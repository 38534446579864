import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import "react-phone-number-input/style.css";
import { lenkieApi } from "../../../utils";
import { toast } from "../../../utils/toast";

interface Props {
	callback: any;
}

export function OnboardingContactComponent(props: Props & any) {
	const { person, mixpanel } = props;
	const {
		register,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: {
			given_name: person.given_name,
			work_phone_number: person.work_phone_number,
			work_email_address: person.work_email_address,
			family_name: person.family_name,
		},
	});

	const [phoneNumber, setPhoneNumber] = useState(
		person.work_phone_number ?? ""
	);

	const onSubmit = async (data: any) => {
		try {
			clearErrors();
			if (!isValidPhoneNumber(phoneNumber)) {
				return setError("work_phone_number", {
					type: "manual",
					message: "Phone number is incorrect.",
				});
			}

			data = {
				...data,
				work_phone_number: phoneNumber,
				work_email_address: person.work_email_address,
			};

			if (!isDirty) {
				await mixpanel.track("Update Contact Details", {
					status: "not-modified",
					screen: "Onboarding",
					path: window.location.pathname,
				});
				return props.history.push("/onboarding/business");
			}

			await mixpanel.setPeople({
				$first_name: data.given_name,
				$last_name: data.family_name,
				$name: `${data.given_name} ${data.family_name}`,
				$email: data.work_email_address,
				$phone: data.work_phone_number,
			});

			const payload: {}[] = Object.entries(data).map(([key, value]) => ({
				op: "replace",
				path: "/" + key,
				value,
			}));

			const { status } = await lenkieApi.patch(
				"/People/" + person.id,
				payload,
				{
					headers: { "Content-Type": "application/json-patch+json" },
				}
			);

			if (status === 204) {
				await mixpanel.track("Update Contact Details", {
					status: "modified",
					screen: "Onboarding",
					path: window.location.pathname,
				});

				await mixpanel.setOnce({
					$created: dayjs().toISOString(),
				});

				toast({ text: "Contact details updated" });
				props.triggerRefreshData();
				return props.history.push("/onboarding/business");
			}
		} catch (error: Error | any) {
			toast({
				text: error?.message ?? "Contact details could not be updated",
				type: "error",
			});

			await mixpanel.track("Update Contact Details", {
				status: "failed",
				screen: "Onboarding",
				path: window.location.pathname,
			});
		}
	};

	useEffect(() => {
		(async function () {
			await props.mixpanel.track("View Contact Details");
		})();
	});

	return (
		<div className='w-full p-8 rounded-lg'>
			<legend className='font-firma-bold text-custom-dark text-3xl'>
				Contact details
			</legend>
			<div className='mt-2'>
				<p className='font-firma-light text-custom-dark text-sm'>
					Please fill in your details in the form fields below.
				</p>
			</div>

			<form
				onSubmit={handleSubmit(onSubmit)}
				className='w-full grid gap-y-6 mt-12 contact'
			>
				<div className='input grid'>
					<label className='text-custom-dark font-firma'>First Name</label>
					<div className='relative'>
						<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
							<span className='text-custom-dark sm:text-sm'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									height='16'
									width='16'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
									/>
								</svg>
							</span>
						</div>
						<input
							{...register("given_name", { required: true })}
							className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
							placeholder='Enter your first name'
							defaultValue={person.given_name}
						/>
					</div>
					{errors.given_name && (
						<span className='text-red-600 text-sm font-firma mt-1'>
							{errors.given_name.message || "This field is required"}
						</span>
					)}
				</div>

				<div className='input grid'>
					<label className='text-custom-dark font-firma'>Last Name</label>
					<div className='relative'>
						<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
							<span className='text-custom-dark sm:text-sm'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									height='16'
									width='16'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
									/>
								</svg>
							</span>
						</div>
						<input
							{...register("family_name", { required: true })}
							className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
							placeholder='Enter your last name'
							defaultValue={person.family_name}
						/>
					</div>
					{errors.family_name && (
						<span className='text-red-600 text-sm font-firma mt-1'>
							{errors.family_name.message || "This field is required"}
						</span>
					)}
				</div>

				<div className='input grid'>
					<label className='text-custom-dark font-firma'>Email address</label>
					<div className='relative'>
						<div className='absolute inset-y-0 left-0 pl-4 opacity-60 flex items-center pointer-events-none'>
							<span className='text-custom-dark sm:text-sm'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									height='16'
									width='16'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
									/>
								</svg>
							</span>
						</div>
						<input
							{...register("work_email_address", { required: true })}
							className='w-full pl-16 border border-gray-200 rounded-lg focus:outline-none focus:border-gray-400 px-4 py-3'
							placeholder='Enter your email address'
							disabled
						/>
					</div>
				</div>

				<div className='input grid'>
					<label className='text-custom-dark font-firma'>Phone number</label>
					<PhoneInput
						international
						defaultCountry='GB'
						value={phoneNumber}
						onChange={setPhoneNumber}
					/>
					<input
						className='hidden'
						{...register("work_phone_number")}
						type='text'
						defaultValue={phoneNumber}
					/>

					{errors.work_phone_number && (
						<span className='text-red-600 text-sm font-firma mt-1'>
							{errors.work_phone_number.message || "This field is required"}
						</span>
					)}
				</div>

				<button className='cursor-pointer bg-custom-dark text-white text-center font-firma rounded-lg p-4 sm:px-8 sm:mt-8 mt-4'>
					Continue
				</button>
			</form>
		</div>
	);
}
