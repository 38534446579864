import { FullLogo } from "./shared/logo-full";

interface Props {
	className?: string;
	text?: string;
}

export function LayoutViewNotFoundWide(props: Props) {
	return (
		<div className={'flex items-center min-h-screen justify-center ' + props.className}>
			<div className='grid justify-center items-center h-fit gap-y-4'>
				<FullLogo className="mx-auto" />
			</div>
		</div>
	);
}
