export const OnboardingInfographicComponent = ({
	scope = [],
	onboardingText = {},
}: any) => {
	return (
		<div className='w-fit h-fit text-left lg:ml-28'>
			<div className='md:ml-auto mb-24 text-white md:w-11/12 lg:w-9/12'>
				<legend className='text-3xl font-firma-bold mb-8 text-custom-orange'>
					{onboardingText.caption}
				</legend>
				<p className='text-white text-md font-firma max-w-lg'>
					{onboardingText.sub}
				</p>
			</div>

			<div className='mb-24 md:ml-auto text-white w-11/12'>
				<legend className='text-lg font-firma-bold mb-8 text-white'>
					My application
				</legend>
				<div className='grid grid-cols-2'>
					<ul className='grid gap-y-2'>
						{scope.map((sc: any, i: any) => (
							<li className='grid' key={i}>
								<span className='flex gap-x-4 items-center text-sm'>
									<span>
										{["completed-inactive", "completed-active"].includes(
											sc.state
										) ? (
											<svg
												width='22'
												height='22'
												viewBox='0 0 22 22'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<circle
													cx='11'
													cy='11'
													r='11'
													fill='#FFE07D'
													fillOpacity='0.27'
												/>
												<path
													d='M10.9997 4.33301C7.32634 4.33301 4.33301 7.32634 4.33301 10.9997C4.33301 14.673 7.32634 17.6663 10.9997 17.6663C14.673 17.6663 17.6663 14.673 17.6663 10.9997C17.6663 7.32634 14.673 4.33301 10.9997 4.33301ZM14.1863 9.46634L10.4063 13.2463C10.313 13.3397 10.1863 13.393 10.053 13.393C9.91967 13.393 9.79301 13.3397 9.69967 13.2463L7.81301 11.3597C7.61967 11.1663 7.61967 10.8463 7.81301 10.653C8.00634 10.4597 8.32634 10.4597 8.51967 10.653L10.053 12.1863L13.4797 8.75968C13.673 8.56634 13.993 8.56634 14.1863 8.75968C14.3797 8.95301 14.3797 9.26634 14.1863 9.46634Z'
													fill='#F5A623'
												/>
											</svg>
										) : (
											<svg
												width='22'
												height='22'
												viewBox='0 0 22 22'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<circle
													cx='11'
													cy='11'
													r='11'
													fill='#F0F0F0'
													fillOpacity='0.25'
												/>
												<path
													d='M14.5936 13.4467L11.9003 11H10.0936L7.40031 13.4467C6.64698 14.1267 6.40031 15.1733 6.76698 16.12C7.13364 17.06 8.02698 17.6667 9.03364 17.6667H12.9603C13.9736 17.6667 14.8603 17.06 15.227 16.12C15.5936 15.1733 15.347 14.1267 14.5936 13.4467ZM12.2136 15.0933H9.78698C9.53364 15.0933 9.33364 14.8867 9.33364 14.64C9.33364 14.3933 9.54031 14.1867 9.78698 14.1867H12.2136C12.467 14.1867 12.667 14.3933 12.667 14.64C12.667 14.8867 12.4603 15.0933 12.2136 15.0933Z'
													fill='#DADADA'
												/>
												<path
													d='M15.2331 5.87967C14.8664 4.93967 13.9731 4.33301 12.9664 4.33301H9.03311C8.02645 4.33301 7.13311 4.93967 6.76645 5.87967C6.40645 6.82634 6.65311 7.87301 7.40645 8.55301L10.0998 10.9997H11.9064L14.5998 8.55301C15.3464 7.87301 15.5931 6.82634 15.2331 5.87967ZM12.2131 7.81967H9.78645C9.53311 7.81967 9.33311 7.61301 9.33311 7.36634C9.33311 7.11967 9.53978 6.91301 9.78645 6.91301H12.2131C12.4664 6.91301 12.6664 7.11967 12.6664 7.36634C12.6664 7.61301 12.4598 7.81967 12.2131 7.81967Z'
													fill='#DADADA'
												/>
											</svg>
										)}
									</span>
									<p
										className={
											"mt-0.5 " +
											(["completed-inactive", "completed-active"].includes(
												sc.state
											)
												? "text-custom-orange"
												: "")
										}
									>
										{sc.text}
									</p>
								</span>
								{i !== scope.length - 1 && (
									<span className='ml-2.5 mt-2'>
										<svg
											width='2'
											height='13'
											viewBox='0 0 2 13'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<line
												x1='1'
												y1='4.37112e-08'
												x2='0.999999'
												y2='13'
												stroke={
													sc.state.split("-")?.includes("completed", "active")
														? "#F5A623"
														: "#F9F9F9"
												}
												strokeWidth='2'
											/>
										</svg>
									</span>
								)}
							</li>
						))}
					</ul>
					<div className='rounded-lg bg-custom-darker p-6 h-fit grid'>
						<div className='flex justify-between'>
							<div>
								<h3 className='font-firma-semibold md:text-sm'>Have questions to ask?</h3>
								<h3 className='font-firma-light md:text-sm'>We’re here to help</h3>
							</div>
							<div>
								<svg
									className="md:h-4"
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M13.19 6H6.79C6.53 6 6.28 6.01 6.04 6.04C3.35 6.27 2 7.86 2 10.79V14.79C2 18.79 3.6 19.58 6.79 19.58H7.19C7.41 19.58 7.7 19.73 7.83 19.9L9.03 21.5C9.56 22.21 10.42 22.21 10.95 21.5L12.15 19.9C12.3 19.7 12.54 19.58 12.79 19.58H13.19C16.12 19.58 17.71 18.24 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.6 16.38 6 13.19 6ZM6.5 14C5.94 14 5.5 13.55 5.5 13C5.5 12.45 5.95 12 6.5 12C7.05 12 7.5 12.45 7.5 13C7.5 13.55 7.05 14 6.5 14ZM9.99 14C9.43 14 8.99 13.55 8.99 13C8.99 12.45 9.44 12 9.99 12C10.54 12 10.99 12.45 10.99 13C10.99 13.55 10.55 14 9.99 14ZM13.49 14C12.93 14 12.49 13.55 12.49 13C12.49 12.45 12.94 12 13.49 12C14.04 12 14.49 12.45 14.49 13C14.49 13.55 14.04 14 13.49 14Z'
										fill='white'
									/>
									<path
										d='M21.9802 6.79V10.79C21.9802 12.79 21.3602 14.15 20.1202 14.9C19.8202 15.08 19.4702 14.84 19.4702 14.49L19.4802 10.79C19.4802 6.79 17.1902 4.5 13.1902 4.5L7.10025 4.51C6.75025 4.51 6.51025 4.16 6.69025 3.86C7.44025 2.62 8.80025 2 10.7902 2H17.1902C20.3802 2 21.9802 3.6 21.9802 6.79Z'
										fill='white'
									/>
								</svg>
							</div>
						</div>

						<div className='mt-8 grid'>
							<a href='tel:+447939498942' className='font-firma-semibold md:text-sm'>
								+44 7939 498942
							</a>
							<a href='mailto:hello@lenkie.com' className='font-firma-semibold md:text-sm'>
								hello@lenkie.com
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
