import { createContext} from "react";
import { Mixpanel } from "../services/mixpanel";

export const MixpanelContext = createContext(Mixpanel);

export const MixpanelConsumer = MixpanelContext.Consumer;

export default function MixpanelProvider(props: any) {
	return (
		<MixpanelContext.Provider value={Mixpanel}>
			{props.children}
		</MixpanelContext.Provider>
	);
}
