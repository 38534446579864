export class Guid {
	static newGuid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
			// tslint:disable-next-line:no-bitwise
			// eslint-disable-next-line no-bitwise
			const r = (Math.random() * 16) | 0,
				v = c === "x" ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}

	static empty() {
		return "00000000-0000-4000-0000-000000000000";
	}
}
